.sessions-history{

  &__title{
    font-size: 24px;
    margin: 10px 0px;
  }
  &__list-container{
    height: calc(100% - 47px);
  }
  &__list{
    margin: 0;
    padding: 0;
    list-style: none;
    transform: translate(0);
  }
}

.session-history{
  border-radius: 6px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 15px;
  padding: 5px 10px;
  font-family: Roboto;
  cursor: pointer;
  margin-bottom: 15px;
  padding-left: 5px;
  background-color: #F6F9FC;

  &__info{
    text-align: left;
    min-width: 50%;
  }
  &__name-wrap{
    font-size: 14px;
    font-weight: 500;
  }
  &__name{
    font-weight: 600;
    font-style: italic;
    letter-spacing: 1px;
  }
  &__date{
    font-size: 12px;
    &--log {
      margin-bottom: 10px;
      font-size: 14px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__date-interval{
    font-weight: 600;
  }
  &__key-value{
    margin-top: 10px;
  }
  &__actions{
    display: flex;
    align-items: center;
    position: relative;

    &--log {
      margin-bottom: 10px;
      font-size: 14px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .session-operators{

      &__wrap{
        position: absolute;
        width: 250px;
        left: -250px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
      }
      &__title{
        margin: 10px 0px 5px;
      }
    }
  }
  &__data-updated {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 14px;
  }
  &__data-updated-item {
    width: 50%;
    &:first-child {
      color: #555;
    }
  }
  &__tooltip{
    font-size: 12px;
    background-color: #dc3545;
    color: #fff;
    border-radius: 4px;
  }
}