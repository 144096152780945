.sub-menu {
  position: absolute;
  left: -84px;
  top: 60px;
  z-index: 6;
  list-style: none;
  margin:0;
  padding: 10px 0;
  width: 155px;
  border-radius: 5px;
  background: #fff;
  font-size: 14px;
  box-shadow: 0 3px 12px rgba(27,31,35,.15);
  font-family: Roboto;	
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 1px;
  color: #24292e;

  &::after {
    left: auto;
    right: 40px;
    top: -14px;
    border: 7px solid transparent;
    border-bottom-color: #fff;
    content: "";
    display: inline-block;
    position: absolute;
  }

  &__header {
    padding: 5px 10px;
    line-height: 1.4;
    cursor: default;
  }
  &__item {
    text-align: center;    
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    padding: 0 10px;
    
    &:hover {
      background-color: #F6F6F8;
      // color: #fff;
      outline: none;
      text-decoration: none;
    }
  }
  &__item-content {
    padding: 10px 0;
    border-bottom: 1px solid #BFBFBF;
  }
  &__separator {
    width: 100%;
    height: 1px;
    background: #CCCCCC;
    margin: 8px 0;
  }
}
