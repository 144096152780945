.user-menu {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-right: 20px;

  &__busy-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: solid 2px #E6EBF1;
    position: absolute;
    left: 1px;
    top: -5px;
  }

  &__settings-drop {
    margin-top: 3px;
    margin-left: 4px;
  }

  &__busy {
    font-size: 32px;
    color: #777;
    font-weight: bold;
    text-decoration: none;
    border: none;
    outline: none;
    margin-left: 15px;
    cursor: unset;
    background-color: none;
    margin: 0 15px;
    
    &--busy {
      color: #ff1a1a;
    }
    &:disabled {
      background: #dddddd;
    }
  }

  &__mode {
    position: absolute;
    left: -5px;
    // margin-top: 16px;
    bottom: -10px;
    font-size: 8px;
    font-weight: 600;
    color: #fff;
    background-color: #a05900;
    padding: 1px 3px;
    white-space: nowrap;
    border-radius: 2px;
  }

  &__img-wrap {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__name {
    margin-left: 15px;
    color: #808080;	
    font-family: Roboto;
    font-size: 14px;
  }

  &__menu {
    position: absolute;
    left: 0;
    top: 40px;
    z-index: 6;
    list-style: none;
    margin:0;
    padding: 10px 0;
    width: 200px;
    border-radius: 5px;
    background: #fff;
    font-size: 14px;
    box-shadow: 0 3px 12px rgba(27,31,35,.15);
    font-family: Roboto;	
    font-size: 12px;
    font-weight: 100;
    letter-spacing: 1px;
    color: #24292e;

    &::after {
      left: auto;
      right: 134px;
      top: -14px;
      border: 7px solid transparent;
      border-bottom-color: #fff;
      content: "";
      display: inline-block;
      position: absolute;
    }
  }
    &__header {
      padding: 5px 10px;
      line-height: 1.4;
      cursor: default;
    }
    &__item {
      text-align: center;    
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      padding: 0 10px;
      
      &:hover {
        background-color: #F6F6F8;
        // color: #fff;
        outline: none;
        text-decoration: none;
      }
    }
    &__item-content {
      padding: 10px 0;
      border-bottom: 1px solid #BFBFBF;
    }
    &__separator {
      width: 100%;
      height: 1px;
      background: #CCCCCC;
      margin: 8px 0;
    }
  }
