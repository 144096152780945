@import '../../../../variables.scss';

.contacts-tabs {
  &__btn {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    display: inline-flex;
    padding: 8px 5px 5px;

    &:hover svg{
      fill: #BFBFBF;
    }
  }
}

.react-tabs {
  width: 100%;
  height: 100%;
  
  &__unread {
    position: absolute;
    left: -12px;
    top: -12px;
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid #0092f2;
    color: #000;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    width: 22px;
    height: 22px;
    line-height: 17px;
    font-size: 9px;
    font-family: Roboto;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
  
  &__tab-list-container {
    display: flex;
    align-items: center;
  }
  &__tab-list {
    list-style: none;
    display: flex;
    align-items: flex-end;
    // margin-bottom: 18px;
    margin: 12px 0;
    padding: 0;
    justify-content: space-between;
    width: 100%;
  }
  &__tab {
    cursor:pointer;
    background-color: $color-lightgrey;
    width: 100%;
    padding: 3px 6px 3px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    white-space: nowrap;
    transition: 0.2s all;
    position: relative;

    color: #FFFFFF;	
    font-family: Roboto;	
    font-size: 12px;	
    letter-spacing: 1px;	
    line-height: 16px;
    border: 1px solid $color-lightgrey;

    &--selected {
      color: black;
      border: 1px solid #0092F2;
      background-color: #fff;
    }
    &--long {
      // max-width: 130px;
      width: unset;
      padding-left: 10px;
    }
    &:not(:first-child) {
      margin-left: 3px;
    }
  }

  &__tab-panel--selected {
    height: calc(100% - 150px);
    // height: 100%;
  }
}

.search-contacts {
  height: 100%;
  overflow: hidden;

  &__load-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__drop-wrap {
    width: 240px;
    position: absolute;
    height: 460px;
    left: 240px;
    top: 160px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 1px #0000003b;
    z-index: 99;
    position: fixed;
    border: solid 1px #0092f2;
    &--girls {
      left: auto;
      right: 240px;
    }
  }

  &__wrap, &__list-container  {
    height: 100%;
  }

  &__list-wrap {
    height: calc(100% - 43px);
  }

  &__list {
    padding: 0 2px;
    margin: 5px 0;
  }

  &__no-items{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 14px;
    color: #bfbfbf;
  }

  &__item {
    display: flex;
    margin: 3px 0;
    cursor: pointer;
    background-color: rgba(238, 238, 238, 0.363);
    
    &:hover {
      background-color: #0092f220;
    }

    &:active {
      background-color: #0092f2;
    }
  }

  &__input-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    border-bottom: solid 1px #0092f2;
  }

  &__search-input {
    border-radius: 4px;
    border: solid 1px #bdbdbd;
    padding: 4px 12px;
    width: calc(100% - 20px);
    outline: none;
    margin: 2px 0;
  }

  &__name {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    width: 170px;

    & span {
      text-overflow: ellipsis;
      overflow: hidden;

      &:nth-child(2) {
        color: #808080;
        font-size: 13px;
      }
    }
  }
  .contact-item__img-wrap {
    border-radius: 50%;
    overflow: hidden;
    height: 40px;
    width: 40px;
    border: solid 1px #0092f2;
  }
}
