.contact-form {
  border: 1px solid #eee;
  box-shadow: 0 2px 2px #ccc;
  background-color: #fff;
  padding: 10px;
  width: 700px;
  text-align: left;
  max-height: 90vh;
  overflow-y: scroll;

  &__lock {
    height: 33px;
    width: 33px;
    position: relative;
    overflow: hidden;
    margin-left: 100px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    margin: 10px 0;
  }
  &__img {
    width: 150px;
    height: 150px;
    margin-top: 10px;
    overflow: hidden;
  }
  &__label {
    font-size: 12px;
    white-space: nowrap;
    flex-basis: 80px;
  }
  &__group {
    margin-bottom: 14px;
    position: relative;
    display: flex;
    align-items: center;

    &--unflex {
    }
  }
  &__group-wrap {
    padding-left: 80px;
  }
  &__input {
    // width: 100%;
    flex-grow: 1;
    padding: 7px 12px;
    font-size: 12px;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  &__btn {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    outline: none;

    &--pin {
      margin: 0 10px;
    }

    &--lg {
      width: 100%;
      padding: 10px 12px;
    }
    &--block {
      display: block;
      margin-left: 80px;
      margin-bottom: 10px;
    }
    &--del {
      -position: relative;
      background: none;
      border: none;
      cursor: pointer;
      padding: 10px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 8px;
        height: 15px;
        width: 2px;
        background-color: red;
      }
      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
}

.full-mode {
  &__wrap {
    height: calc(100vh - 100px);
    display: flex;
  }

  &__card-wrap {
    width: 400px;
  }

  &__chat-wrap {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    height: 100%;

    .chat {
      flex-grow: 1;
      min-height: 300px;
      width: 652px;

      .spinner-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      .chat__wrapper {
        height: calc(100% - 44px);
        flex-grow: 1;
      }
    }
  }
}

.call-modal {
  border: 1px solid #eee;
  box-shadow: 0 2px 2px #ccc;
  background-color: white;
  width: 700px;
  text-align: center;

  &__wrap {
    display: flex;
  }

  &__call-info {
    // width: 55%;
    padding: 10px;
  }

  &__redirect {
    width: 45%;
    padding: 10px;
  }

  &__timer-wrap {
    position: relative;
    padding: 0px 5px 5px;
    margin-bottom: 5px;

    &--title {
      margin-bottom: 10px;
    }

  }

  &__active-users {
    width: 100%;
    padding: 5px;

    &--title {
      margin-bottom: 5px;
    }
    &--redirect-list {
      width: 100%;
      height: 100px;
      padding: 5px 15px;

      .redirect-list {
        list-style: none;
        margin: 0;
        padding: 0;

        &__item {
          display: flex;
          position: relative;
          align-items: center;
          width: 100%;
          height: 40px;
          border: 1px solid #f6f6f6;
          background-color: #f6f6f6;
          border-radius: 6px;
          margin: 5px 0;
          padding: 3px 5px;
          outline: none;

          &--img-wrap {
            width: 32px;
            height: 32px;
            position: relative;
            margin-right: 5px;
            min-width: 32px;
          }
          &--info {
            color: #808080;
            display: flex;
            align-items: center;
            width: 100%;
            font-size: 14px;
          }
          &--redirect-btn {
            position: absolute;
            right: 0;
            display: none;
          }
          &--pending{
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
          }
          &--error {
            position: absolute;
            bottom: 1px;
            right: 0;
            left: 0;
            font-size: 11px;
            color: red;
          }
                    
          &:hover .redirect-list__item--redirect-btn {
            display: block;
          }
        }

        &__no-operators {
          font-size: 14px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-style: italic;
        }
      }
    }
  }

  &__nationality {
    display: flex;
    margin: 10px;
    justify-content: center;

    & img {
      width: 25px;
      height: 14px;
      object-fit: cover;
    }
  }

  &__img-wrap {
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }
  &__name {
  }
  &__btns {
    margin-top: 30px;
  }
  &__btn {
    border: 1px solid #0092f2;
    border-radius: 4px;
    outline: none;
    margin-right: 10px;
    padding: 6px 16px;
  }
  &__tags-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 10px 0;
    padding: 0;
    justify-content: center;
  }
}

.agent-select {
  flex-grow: 1;
  font-size: 12px;

  &__menu {
    max-height: 180px;
    overflow: hidden;
  }
  &__menu-list {
    height: 100%;
  }
}
