@import "../../../../variables.scss";

.chat-header {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2);
  padding: 12px 10px 8px;
  background-color: #fff;
  z-index: 7;

  &--with-agent {
    background-color: rgba(253, 5, 232, 0.15);

    .chat-header__name {
      color: rgb(151, 151, 151);
    }
  }

  &:hover .booked-dropdown {
    display: flex;
    align-items: center;
    padding: 0;
    opacity: 1;
    height: 30px;
  }

  &__action-buttons {
    display: flex;
    margin-left: auto;
    margin-bottom: -17px;
    align-items: flex-end;
    position: relative;
  }

  &__mark-button {
    display: flex;
    padding: 1 3px;
    span {
      transition: all 0.2s ease-in;
      border-right: solid 14px transparent;
      border-bottom: solid 14px #808080;
    }
    &--marked span {
      border-bottom: solid 14px #f00;
    }
  }

  &__email-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5px;
  }

  &__email-item {
    display: flex;
    color: $color-black;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin: 2px 0;
    white-space: nowrap;
    cursor: pointer;

    &-email {
      margin-left: 2px;
    }

    &:hover {
      & span {
        text-decoration: underline;
      }
    }
  }

  &__session {
    display: flex;
    padding: 0 6px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__pin-icon {
    display: flex;
    font-size: 15px;
    padding: 0 3px;
    cursor: pointer;

    svg {
      color: #808080;
      transform: rotate(0deg);
      transition: all 0.3s ease;
    }

    &--pinned {
      svg {
        color: #0092f2;
        transform: rotate(45deg);
      }
    }
  }

  &::after {
    box-sizing: border-box;
    display: inline-block;
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    top: 8px;
    left: 10px;
    border: 2px solid rgb(230, 235, 241);
    // animation: available-pulse 1s infinite;
    transition: all 0.2s ease-in;
  }

  &--available {
    &::after {
      content: "";
      background-color: rgb(1, 223, 133);
    }
  }

  &--off {
    &::after {
      content: "";
      background-color: #f8737f;
    }
  }

  &__header {
    display: flex;
    align-items: center;

    .name__copy {
      &--btn {
        padding: 0;
      }
    }
  }
  &__ava-wrap {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    cursor: pointer;
    // margin-right: 5px;
  }
  &__ava {
    border-radius: 50%;
  }
  &__ex {
    color: #f27e3f;
    font-weight: bold;
  }
  &__name {
    color: #bfbfbf;
    font-size: 14px;
    width: 100%;
    margin: 0 0 0 10px;
    padding-right: 32px;
    display: -webkit-box;
    overflow: hidden;
    max-height: 32px;
    text-align: left;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &__deteils {
    padding-top: 3px;
    color: #bfbfbf;
    font-size: 14px;
    width: 100%;
    margin: 0 0 0 10px;
    padding-right: 32px;
    text-align: left;
  }
  &__rating {
    white-space: nowrap;
    svg {
      color: #f2cc0c;
    }
  }
  &__show-review-list {
    margin: 0 2px;
    padding: 0;
    color: #0092f2;
    text-decoration: underline;
    cursor: pointer;
  }
  &__btn {
    &--related {
      padding: 1px 4px;
      border-radius: 2px;
      font-size: 11px;
      color: #fff;
      width: 20px;
      background-color: $color-grey;
      height: 16px;
      position: relative;

      &:hover {
        background-color: #0092f2;

        // &::after {
        //   content: "Take / Release";
        //   background-color: #333;
        //   white-space: nowrap;
        //   font-size: 13px;
        //   border: solid 1px #fff;
        //   color: #fff;
        //   padding: 4px 8px;
        //   position: absolute;
        //   left: 20px;
        //   top: 15px;
        // }
      }
    }
    &--info {
      position: absolute;
      right: 0px;
      top: 4px;
      padding: 3px 10px;
      cursor: pointer;
      border: none;
      outline: none;
      background: transparent;
    }
    &--available {
      padding: 0 3px;
    }
    &--prices {
      position: absolute;
      right: 10%;
      top: 4px;
      padding: 3px 10px;
      cursor: pointer;
      border: none;
      outline: none;
      background: transparent;
      color: #0092f2;
    }
  }
  &__btn--agent {
    color: #0092f2;
    display: flex;
    align-items: center;
    text-decoration: underline;
    text-align: left;

    &:hover {
      color: #000;
      text-decoration: none;
    }
    &:hover svg {
      fill: #000;
    }
    svg {
      margin-right: 10px;
    }
  }
  // &__btn--info:hover { // Remove
  //   .contact-details {
  //     display: block;
  //     cursor: default;
  //   }
  // }
  &__text-line {
    margin: 0;
  }
  &__text {
    white-space: pre-line;
    font-size: 13px;
    border: none;
    // overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none;
    text-align: center;
    // height: 14px;
    width: 100%;
    padding: 0;
    // overflow: hidden;

    &--link {
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &__info {
    display: none;
    background-color: #fff;
    position: absolute;
    z-index: 5;
    right: 0;
    width: 220px;
    padding: 26px 6px 16px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 3px 25px -7px rgba(0, 0, 0, 0.47);
  }

  &__actual-bookings {
    font-size: 15px;
    padding: 0px 3px !important;
  }

  &__text-line {
    display: flex;
    align-items: center;
    text-align: left;
    margin: 2px 0;
  }

  &__link-title {
    // max-width: 45%;
    // overflow: hidden;
    // text-overflow: ellipsis;
    margin-left: 5px;
  }

  &__link-body {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    text-decoration: none;
    color: #0092f2;

    &:hover {
      opacity: 0.8;
    }
  }

  &__hr {
    width: 100%;
    position: relative;
    height: 44px;
    background: linear-gradient(
      #fff 48%,
      #000 49%,
      #000 52%,
      #fff 52%,
      #fff 100%
    );
  }
  &__btn--share {
    position: absolute;
    background-color: #fff;
    padding: 2px 20px;
    left: calc(50% - 28px);
    cursor: pointer;
    border: none;
    outline: none;
    top: 8px;

    &:active {
      top: 6px;
    }
  }
  &__footer {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    position: relative;
    padding-right: 15px;
  }
  &__external-link {
    position: absolute;
    right: 0px;
    top: 5px;
    cursor: pointer;
    font-size: 17px;
    color: #0092f2;

    &:hover {
      opacity: 0.8;
    }
  }
  &__booked-dropdown {
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 80px;
  }
  &__btn {
    display: flex;
    align-items: center;
    padding: 0;
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;

    // &:first-of-type {
    // margin-right: 15px;
    // }
    &:disabled {
      cursor: not-allowed;
    }
  }
  &__img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  &--hidden {
    height: 74px;
    background-color: #fff;
  }
  &--hidden > &__header,
  &--hidden > &__footer {
    display: none;
  }
  &__block-info {
    border: 1px solid rgba(27, 31, 35, 0.15);
    position: absolute;
    background: #fff;
    border-radius: 5px;
    font-size: 14px;
    padding: 5px;
    z-index: 1;
    box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);
  }
  &__row {
    display: flex;
    align-items: center;
    position: relative;

    .tel {
      &__copy--wrapper {
        padding-right: 10px;
      }

      &__copy--btn {
        padding: 0;
      }
    }

    .events-auto {
      pointer-events: auto !important;
    }
  }

  .contact-tels-form {
    top: 75px;
    left: 0px;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0;

    &--tag {
      font-size: 11px;
      line-height: 15px;
      background: #f6f6f8;
      height: 20px;
      padding: 2px 6px;
      border-radius: 5px;
      margin-right: 5px;
      margin-bottom: 5px;
      cursor: pointer;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__exclusive {
    margin-left: 4px;
    font-size: 13px;
    color: #f2cc0c;
  }
}

.call-btn {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  &:disabled {
    cursor: not-allowed;

    & > svg {
      fill: grey;
    }
  }

  &__option {
    padding: 2px;
  }

  &__dropdown {
    background-color: $color-blue;
    border-radius: 5px;
    box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);
    position: absolute;
    top: 103%;
    padding: 5px;
    z-index: 100;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 100;
    letter-spacing: 1px;

    button {
      color: white;
    }

    .call-btn {
      width: unset;
      height: unset;
    }
    &--right {
      right: 0;
    }
  }
}

.attachment-contact__btn + .call-btn__dropdown {
  top: 75%;
}

.contact-bookings {
  &__open {
    position: absolute;
    right: 0px;
    top: 5px;
    cursor: pointer;
    font-size: 17px;
  }
}
