input.copy-input{
  top: -9999px;
  left: -9999px;
  opacity: 0;
  pointer-events: none;
  position: absolute
}

.clipboard-icon {
  font-size: 13px;
}