.contact-tels-form {
  position: absolute;
  top: -39px;
  z-index: 2;
  background-color: #fff;
  width: 100%;
  padding-bottom: 56px;
  border-bottom: 2px solid #0092f2;
  border-radius: 0 0 6px 6px;

  &__title{
    font-size: 14px;
    color: #808080;
    padding-left: 30px;
    margin: 30px 0;
  }
  &__input{
    outline: none;
    border: 1px solid #0092f2;
    border-radius: 6px;
    padding: 12px 10px;
    margin-left: 5px;
    font-size: 12px;
    width: 100%;

    &--removable{
      padding-right: 25px;
    }
    &--error{
      border-color: #dc3545;
    }
  }
  &__select-tel-label {
    width: 180px;
    margin-right: 5px;
    margin-left: 5px;
    &--removable {
      width: 190px;
    }
    &--pending {
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f6f6f6;
      border: 1px solid #f6f6f6;
    }
  }
  &__radio{
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
    border: 2px solid #0092f2;
    border-radius: 100%;
    cursor: pointer;

    &--checked{
      border-width: 5px;
    }
  }
  &__actions{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  &__cancel, &__submit {
    color: #fff;
    border-radius: 6px;
    box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  &__cancel{
    background-color: #bfbfbf;
    padding: 6px 21px;
  }
  &__submit{
    background-color: #05df84;
    padding: 11px 9px;
    margin-left: 30px;

    &:disabled{
      cursor: not-allowed;
    }
  }
  &__field{
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 10% 15px;
  }
  &__add-field{
    color: #808080;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0;
    margin-left: 10%;
    margin-top: 10px;

    svg {
      margin-right: 5px;
    }
  }
  &__remove-field{
    position: absolute;
    right: 10px;
    width: 16px;
    height: 16px;
    padding: 0;
    top: 12px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      display: block;
      transform: rotate(45deg);
      height: 1px;
      position: absolute;
      width: 18px;
      background-color: #F8737F;
      transform-origin: 50% 50%;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      transform: rotate(-45deg);
      height: 1px;
      width: 18px;
      background-color: #F8737F;
      transform-origin: 50% 50%;
    }
  }

  .form-error-text__wrap {
    bottom: -1px;
    margin-left: 20px;
  }
}