.modal-error {
  border: 1px solid #eee;
  padding: 100px 10px;
  width: 700px; 
  background-color: white;
  text-align: center;

  &--small {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 400px;
  }
}
