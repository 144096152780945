.chat-sources {
  position: relative;
  display: flex;
  background-color: #f6f6f8;
  padding-right: 25px;

  &__source {
    padding: 10px;
    border-bottom: 1px solid transparent;
    position: relative;

    &--selected {
      background-color: #fff;
      border-bottom: 1px solid #0092f2;
    }
  }

  &__count {
    position: absolute;
    right: 5px;
    top: 15px;
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid #0092f2;
    color: #000;
    font-weight: bold;
    width: 16px;
    height: 16px;
    font-size: 9px;
    font-family: Roboto;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }

  &__search-form {
    width: 10%;
    height: 75px;
    position: absolute;
    padding: 0;
    right: 0;
    transition: all 0.2s linear;

    &--open {
      width: 100%;
      background-color: #fff;
      z-index: 2;

      .chat-sources__search-input {
        width: 100%;
        background: #fff;
        padding-right: 40px;
        border: 1px solid #0092f2;
        border-radius: 5px;
      }
    }
  }

  &__search-input {
    display: inline-block;
    position: absolute;
    right: 0;
    width: 0;
    height: 39px;
    padding: 10px 25px 10px 10px;
    background: none;
    border: 1px solid transparent;
    outline: none;
    transition: all 0.2s linear;
  }

  &__btn {
    position: absolute;
    right: 0;
    top: 10px;

    &--remove {
      right: 25px;
      position: absolute;
      cursor: pointer;
      padding: 15px;
      height: 12px;
      width: 12px;
      border: none;
      outline: none;
      background-color: transparent;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 3px;
        height: 15px;
        width: 2px;
        background-color: red;
      }
      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  &__search-all-btn {
    opacity: 0;
    position: absolute;
    top: auto;
    bottom: 3px;
    width: 0;
    height: 24px;
    padding: 0;
    border-radius: 6px;
    overflow: hidden;
    color: #00A6AD;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    background-color: white;
    transition: opacity 0.4s;

    &--open {
      opacity: 1;
      right: 2px;
      width: auto;
      padding: 0 10px;
      color: #fff;
      background-color: #0092f2;
      transition: opacity 0.4s;
    }
  }
}

.sales-popup {
  position: absolute;
  left: 0;
  top: auto;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2);
  padding: 7px 10px 10px;
  z-index: 9;
  display: flex;
  align-items: center;

  &__btn {
    color: #fff;
    background-color: #0092f2;
    border-radius: 5px;
    font-size: 13px;
    line-height: 1.3;
    user-select: none;
    padding: 6px 10px;
    margin-bottom: 5px;
    max-width: 200px;

    &--cancel {
      margin-left: 10px;
      background-color: #bfbfbf;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #bfbfbf;
    }
  }
}
