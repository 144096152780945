.create-room-dialog {
  &__main-wrap {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 6;
    right: 0px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 5px;
  }
  &--add-mode .create-room-dialog__chosen {
    top: 0;
  }
  &__chosen {
    list-style: none;
    padding: 0;
    margin: 0 0 0 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: absolute;
    z-index: 19;
    top: 55px;
    max-width: calc(100% - 34px);
    overflow-x: scroll;
    padding-bottom: 4px;

    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-track-piece {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #dbdee4;
      border-radius: 6px;
    }

    & > li {
      background-color: #00a562;
      color: #fff;
      padding: 1px 5px;
      border-radius: 4px;
      margin: 0 2px;

      &:first-child {
        margin-left: 0;
      }
    }

    & > li[data-isonline="online"] {
      background-color: #00a562;
    }
    & > li[data-isonline="offline"] {
      background-color: #bdbdbd;
    }
    & > li[data-isonline="busy"] {
      background-color: #f8737f;
    }
  }

  &__wrap {
    height: 100%;
    margin-top: 0;
    z-index: 9;
    background-color: #fff;

    .create-room-dialog {
      height: calc(100% - 32px);
    }
  }

  &__search-input {
    width: calc(100% - 20px);
    margin: 0;
    border: none;
    border-radius: 6px;
    background-color: #e6ebf1;
    outline: none;
    padding: 12px 10px;
    margin: 20px 10px 5px;

    &::placeholder {
      font: 14px sans-serif;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  &__show-search {
    color: #fff;
    position: absolute;
    right: 0;
    height: 44px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    bottom: 38px;
    cursor: pointer;
    z-index: 888;
  }

  &__close {
    cursor: pointer;
    border: none;
    outline: none;
    background-color: none;
    z-index: 1;
    padding: 15px;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 15px;
    width: 15px;
    z-index: 9;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 8px;
      height: 15px;
      width: 2px;
      background-color: red;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }

  &__header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #fff;
    z-index: 4;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    height: 119px;
    min-height: 119px;
    position: relative;
  }

  &__room-info {
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2);
    padding: 12px 40px 36px 10px;
    z-index: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__fake-chat-header {
    display: flex;
    align-items: center;
    position: relative;
    background-color: #f6f6f8;
    opacity: 0.5;
    cursor: default;

    & button {
      cursor: default;
      padding: 7px 15px 8px;

      &:first-child {
        background-color: #fff;
        border-bottom: 1px solid #0092f2;
      }

      &:last-child {
        position: absolute;
        right: -9px;
      }
      &:nth-child(2) {
        margin-left: auto;
        margin-right: 30px;
        padding-bottom: 6px;
      }
    }
  }

  &__logo-wrapper {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__logo {
    width: 100%;
  }

  &__input {
    width: 100%;
    outline: none;
    display: -webkit-box;
    font-size: 14px;
    border: none;
    background-color: #fcfcfd;
    margin-left: 10px;
    color: #bfbfbf;
    word-wrap: break-word;
    border-bottom: 1px solid transparent;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;

    &::placeholder {
      font: 14px sans-serif;
      color: #bfbfbf;
    }
  }

  &__list {
    padding: 0 10px;
    min-height: 250px;
    position: relative;
    margin: 0;
  }
  &__list-container {
    height: 100%;
    background-color: #eeeeee70;
  }
  &__list-wrap {
    height: calc(100% - 67px);
    overflow: hidden;
  }
  &__no-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 14px;
    color: #bfbfbf;
  }

  .contact-item {
    position: relative;
    height: 48px;
    width: 100% !important;
    border: none;
    display: flex;
    align-items: center;
    background-color: #e6ebf1;
    margin: 4px 0;
    border: 1px solid transparent;
    outline: none;

    &__name {
      width: 60px;
    }

    &:hover:not([data-checked="true"]) {
      background-color: #fff;
      border: 1px solid #0092f2;
    }

    &__checkbox {
      position: relative;
      right: 6px;
      outline: none;
      height: 20px;
      font-size: 20px;
    }
  }

  &__btn--submit {
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    background-color: #0092f2;
    border-radius: 0px 0px 3px 3px;
    align-self: center;
    font-size: 13px;
    width: 100%;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    padding: 10px 5px;
    height: 33px;

    & img {
      object-fit: contain;
    }

    &:disabled {
      background-color: #bdbdbd;
      cursor: not-allowed;
    }

    &:active {
      transform: translateY(1px);
    }
  }
}

@keyframes bounceInRight {
  from {
    opacity: 0;
    transform: translateX(-300px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
