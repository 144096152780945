.parsed-girl-item {
  height: 100%;

  .booked-editor--editing {
    color: #000;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin: 5px 0;
    cursor: pointer;
    color: #808080;
    border: 1px solid #e8e8e8;
    border-radius: 7px;
    overflow: hidden;
    background-color: #f6f9fc;
  }

  &__text-info-block {
    width: 100%;
    max-width: 248px;
    padding: 5px;
  }

  &__status-wrapper {
    padding: 0 10px;
  }

  &__status-point {
    display: block;
    background-color: #d8d8d8;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }

  &__text-info-top {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eaeef3;
    padding: 0 2px 5px;
  }

  &__text-info-bottom {
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__img-wrap {
    min-width: 40px;
    min-height: 40px;
    width: 70px;
    height: 100px;
    overflow: hidden;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 5px;
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 600;
    max-width: 50%;
  }

  &__booking-btn-wrap {
    padding: 4px 6px 2px;
    border-radius: 4px;
    svg {
      margin: 0;
    }
    &:hover {
      background-color: #eee;
    }
  }

  &__btn-wrap {
    display: flex;
    margin-left: auto;
    align-items: center;
    svg {
      color: #03a7ae;
      fill: #03a7ae;
    }
  }

  &__btn {
    border-radius: 4px;
    padding: 4px 6px;
    line-height: 1;
    display: flex;
    align-items: center;

    .call-btn {
      padding: 0;
    }

    &:hover {
      background-color: #eee;
    }

    &:nth-child(1) {
      color: #01DF85;
    }
    &:nth-child(2) {
      color: #F2CC0C;
    }
    &:last-of-type {
      color: #0092F2;
    }
    
    &:disabled {
      cursor: not-allowed;

      svg {
        color: #808080;
      }
    }
  }
}