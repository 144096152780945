.off-today {
  &__wrap,
  &__pending,
  &__no-items {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 6;
  }

  &__no-items {
    font-size: 14px;
    font-style: italic;
  }

  &__list {
    width: 100%;
    height: 100%;
    list-style: none;
    padding: 15px 0 0 5px;

    &--item {
      position: relative;
      width: 100%;
      margin-bottom: 5px;
    }
  }
}
