.lazy-load__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  -background: #fff;
  border-radius: 50%;
}
/* .lazy-load__error {
  position: relative;
  top: 150px;
  z-index: 20;
  left: 80px;
  font-size: 30px;
} */
.lazy-load__image {
  display: block;
}
.lazy-load__image--hidden {
  display: none;
}
.lazy-load__spinner {
  width: 100%;
  height: 100%;
}