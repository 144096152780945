.mobile-plug {
  margin: 0 auto;
  padding: 0 5px;
  width: 320px;

  &__logo {
    margin: 124px auto 0;
    display: block;
    width: 110px;
    height: 109px;
  }

  &__title {
    margin: 75px 0 0;
    
    font-family: Roboto, sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
    text-align: center;
    color: #404040;
  }

  &__text {
    margin: 38px 0 0;

    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 1.35;
    text-align: center;
    color: #404040;
  }
}
