.message {
  &__form {
    // padding: 0 25px 0 5px;
    padding: 0 5px;
    position: relative;
    display: flex;
    // width: calc(100% - 20px);
    // margin: 0 auto;
    align-items: center;
    border: 1px solid #c2d1d9;
    border-radius: 6px;
    background: #fff;
    margin: 0 10px 10px;

    &:focus-within {
      border: 1px solid #0092f2;
    }
  }
  &__input {
    flex-grow: 1;
    margin: 10px 5px;
    padding: 5px;
    outline: none;
    border: none;
    font-size: 14px;
    background: none;
    color: inherit;
    resize: none;
    max-height: 250px;
    line-height: 1.2;
    border-radius: 5px;
    background-color: transparent;
    transition: background-color linear 1s;

    &--changed {
      background-color: #cce9fd;
      transition: background-color linear .05s;
    }

    &:disabled {
      filter: blur(1px);
      cursor: not-allowed;
    }

    &:focus > .message__btn svg {
      fill: #01df85;
    }
    &::placeholder {
      font: 14px sans-serif;
      color: #d7d7d8;
    }
  }

  &__dispatch-type {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 2px;

    button {
      padding: 0;

      &:disabled{
        opacity: 0.2;
        cursor: default;
      }

      svg {
        padding: 4px 3px;
        box-sizing: content-box;
      }

      &:hover{
        background-color: rgba(255, 255, 255, 0.404);
        box-shadow: inset 0 0 0 1px #eee;
      }
    }

    &--active{
      // background-color: white;
      box-shadow: inset 0 0 0 1px #eee;

      svg{
        color:#0092F2
      }
    }
  }

  &__controls {
    display: flex;
    align-items: center;
  }

  &__attach-icon {
    color: #808080;
    cursor: pointer;
    
    &--disabled {
      opacity: .4;
      cursor: default;
    }
  }

  &__btn {
    display: inline-flex;

    &--emoji {
      &:hover svg > g {
        fill: #0092f2;
      }
    }
  }

  &__input-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 12px;
    height: 30px;
    position: relative;

    .message__btn--emoji {
      padding: 10px 0 10px 10px;
    }
  }

  &__header-hr {
    margin: 0;
    border-top: 1px solid #0092f2;
    opacity: 0.4;
    border-bottom: none;
    transition: 0.2s all;
    width: 60px;

    @media screen and (max-width: 1280px) {
      width: 30px;
      margin: auto;
    }
  }
  &__header-buttons {
    justify-content: flex-end;
    display: flex;
    width: calc(50% - 30px);

    @media screen and (max-width: 1280px) {
      width: calc(50% - 15px);
    }
  }

  &__shortcut {
    position: absolute;
    cursor: pointer;
    font-size: 13px;
    display: inline-block;
    padding: 2px 5px;
    background-color: #0092f2;
    color: #fff;
    border-radius: 3px;
    z-index: 3;
    overflow: hidden;
    width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: #0092f2;
      background-color: #fff;
      box-shadow: 0px 2px 10px #999999;
    }
  }

  &__indicator {
    display: flex;
    font-size: 13px;
    align-items: flex-end;
    width: calc(50% - 30px);

    @media screen and (max-width: 1280px) {
      width: calc(50% - 15px);
    }
  }

  &__indicator-text {
    color: #bdbdbd;
    font-size: 11px;
    white-space: nowrap;
  }

  &__mention-list {
    position: absolute;
    bottom: 50px;
    z-index: 3;

    border: 1px solid green;
    list-style: none;
    padding: 10px 15px;
    margin: 0;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
    background-color: #F6F9FC;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    max-height: 312px;
    width: 100%;

    overflow-y: auto;
  }
}

.message-forbid {
  bottom: 0;
  background-color: #f6f9fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  font-family: Roboto;

  & ~ .message__form,
  & ~ .message__input-header {
    // display: none;
    position: fixed;
    bottom: -50vh;
  }

  &__open-editor {
    background-color: #0092f2;
    color: #fff;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 600;
    padding: 9px 20px 7px;
    border-radius: 6px;
    margin-bottom: 8px;
    transition: background-color 0.25s;

    &:hover {
      background-color: #007bce;
    }
    &:disabled {
      cursor: not-allowed;

      &:hover {
        background-color: #0092f2;
      }
    }
  }
  &__input {
    background-color: #fff;
    border: 1px solid #f20c0c;
    border-radius: 6px;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    color: #f20c0c;
    margin: 0 10px 9px;
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px 12px;
  }
  &__text {
  }
  &__send-icon {
    color: #bfbfbf;
  }
}

.mention {
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
  color: #858181;
  font-size: 12px;
  background-color: #e6ebf0;
  margin-bottom: 5px;
  border-radius: 5px;

  &--active {
    background-color: #0092f2;
    color: #fff;
  }

  &:hover {
    background-color: #0091f286;
    color: #fff;
  }

  &__ava-wrap {
    width: 32px;
    min-width: 32px;
    height: 32px;
    position: relative;
    background-color: #ffffff;
    border-radius: 50%;
  }
  &__ava {
    border-radius: 50%;
  }
  &__status {
    width: 8px;
    height: 8px;
    position: absolute;
    border-radius: 50%;
    top: -3px;
    left: 0;
    background-color: red;
    z-index: 2;
    border: 1px solid rgb(230, 235, 241);
  }
  &__name {
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
// ChatBoilerplate styles
.template {
  height: 100%;
  display: flex;
  align-items: center;

  &__list {
    list-style: none;
    background-color: #fff;
    width: 220px;
    max-height: 140px;
    overflow: auto;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 10px;
    right: -10px;
    z-index: 9;
  }
  &__item {
    font-size: 13px;
    text-align: center;
    padding: 8px 0;
    border-bottom: 1px solid #000;
    cursor: pointer;

    &:hover {
      background-color: #f6f6f6;
      outline: none;
      text-decoration: none;
    }
  }
  &__icon {
    padding: 10px 0px 10px 10px;
    cursor: pointer;
    display: inherit;

    &:hover svg {
      fill: #bfbfbf;
    }
  }
}

.emoji-mart {
  position: absolute;
  bottom: 29px;
  right: -12px;
  width: 250px !important;
  z-index: 6;
}
.emoji-mart-preview {
  display: none;
}

.voice-message {
  &__record {
    width: 28px;
    height: 25px;
    display: flex;

    &:disabled {
      opacity: .4;
      cursor: default;
    }

    svg {
      z-index: 2;
    }
  }
  &__record-wrap {
    position: relative;
  }
  &__record-bg {
    position: absolute;
    width: 26px;
    height: 25px;
    background-color: #0092f2;
    border-radius: 100%;
    z-index: 2;
    left: 1px;
    top: 0px;
    animation: startRecord 4s infinite, initRecord 0.5s;
  }
  &__info {
    position: absolute;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 40px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    padding: 4px;
    padding-right: 40px;
    top: 0px;
    right: 0px;
    font-size: 14px;
    animation: initRecordInfo 0.5s;
    overflow: hidden;
    z-index: 1;
  }
  &__time {
    display: flex;
    align-items: center;
  }
  &__indicator {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #dc3545;
    animation: indicator 1s infinite;
    margin: 0px 5px;
  }
  &__send {
    padding: 0;
    margin: 0;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
      content: "";
      display: none;
      position: absolute;
      width: 22px;
      height: 22px;
      top: -2;
      left: -4px;
      border-radius: 100%;
      background: rgba(0, 146, 242, 0.2);
    }

    &:hover::before {
      display: block;
    }
  }
  &__stop {
    color: white;
    background-color: #dc3545;
    width: 12px;
    height: 12px;
    margin-left: 10px;
    position: relative;

    &::before {
      content: "";
      display: none;
      position: absolute;
      width: 22px;
      height: 22px;
      top: -5px;
      left: -5px;
      border-radius: 100%;
      background: rgba(220, 53, 69, 0.2);
    }

    &:hover::before {
      display: block;
    }
  }
}

@keyframes startRecord {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1.5, 1.5);
  }
  50% {
    transform: scale(1, 1);
  }
  75% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes initRecord {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes indicator {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes initRecordInfo {
  0% {
    opacity: 0;
    width: 0px;
  }
  99% {
    width: 119px;
  }
  100% {
    width: auto;
    opacity: 1;
  }
}

.custom-scroll {
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-button {
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin: 5px;
  }
}
