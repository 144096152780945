.profiles-for-edit {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-wrap {
    width: 345px;
    height: 550px;
    margin: auto;
    border: 1px solid #c3bfbf;
    border-radius: 6px;
    background-color: #e5ebf1;
  }

  &__wrap {
    height: 100%;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.53);
  }

  &__input-wrap {
    position: relative;
    padding: 10px 10px 0;
    border-radius: 6px 6px 0 0;
    font-size: 16px;
    background-color: #e5ebf1;

    &--hidden {
      display: none;
    }

    svg {
      position: absolute;
      right: 14%;
      bottom: 22%;
      color: #808080;
      cursor: pointer;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    padding-right: 12px;
  }

  &__list-wrap {
    flex-grow: 1;
    height: inherit;
    padding: 10px;
    padding-right: 2px;
  }

  &__list-container {
    position: relative;
    height: 100%;
  }

  &__no-items{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #bfbfbf;
    font-size: 14px;
  }

  &__search-input {
    width: 92%;
    padding: 8px;
    border: 1px solid #c8c9c9;
    border-radius: 4px;
    outline: none;
    color: #5d5f61;
    font-weight: 600;
    letter-spacing: 0.88px;
    &::placeholder {
      font-weight: 100;
    }
  }

  &__load-wrap {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  &__close-btn {
    z-index: 2;
    position: absolute;
    top: 13px;
    right: 4px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    &:before, &:after {
      position: absolute;
      top: 5px;
      right: 14px;
      width: 2px;
      height: 20px;
      content: '';
      background-color: #f00;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.profiles-for-edit-item {
  display: flex;
  position: relative;
  align-items: flex-start;
  margin: 5px 0;
  border: 1px solid #e8e8e8;
  border-radius: 7px;
  list-style: none;
  color: #808080;
  cursor: pointer;
  background-color: #f6f9fc;

  &:hover {
    border: 1px solid #0092f2;
    transition: .2s;
  }

  & .profile-rates {
    top: 2px;
    right: 4px;
    width: 210px;
    cursor: default;
    &__title {
      text-align: center;
    }
    &__header, &__items {
      padding: 5px;
    } 
  } 

  &__profile-info {
    margin-top: 10px;
    color: #808080;
    &-block {
      width: 100%;
      max-width: 248px;
      padding: 7px;
    }
  }

  &__img-wrap {
    position: relative;
    width: 70px;
    min-width: 40px;
    height: 100px;
    min-height: 40px;
    border-radius: 7px 0 0 7px;
    overflow: hidden;
    cursor: pointer;
  }

  &__name {
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 600;
  }

  &__show-prices {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 2px 5px;
    border-radius: 100%;
    color: #fff;
    font-size: 8px;
    background-color: #0092f2;
  }
}