@import "../../variables.scss";

.profile-labels {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-right: 3px;
  padding-bottom: 3px;
  // overflow: hidden;

  &__label {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    width: fit-content;
    border-radius: 4px;
    color: white;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 5px 6px;
    margin-top: 3px;
    margin-left: 3px;
    white-space: nowrap;

    &-tooltip {
      position: absolute;
      z-index: 1;
      bottom: 24px;
      left: 0;
      visibility: hidden;
      padding: 5px 10px;
      width: 155px;
      border-radius: 6px;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      background-color: $color-lightprimary;
      text-align: center;
      font-size: 12px;
      white-space: pre-wrap;
      letter-spacing: 1px;
      color: $color-black;
    }

    &--contact-det {
      font-size: 9px;
      padding: 3px 5px;
    }
    &--table-item  {
      font-size: 7px;
      padding: 2px 3px;
    }
    &--bi {
      background-color: $color-yellow;
    }
    &--secret {
      background-color: $color-pink;
    }
    &--hetero {
      background-color: $color-blue;
    }
    &--green {
      background-color: $color-green;
    }
    &--red {
      background-color: $color-red;
    }
    &--lightpink {
      background-color: $color-lightpink;
    }
    &--recommended {
      background-color: $color-blue;
    }
    &--featured {
      background-color: $color-mediumpurple;
    }
    &--grey {
      background-color: $color-grey;
    }
    &--on-holiday {
      background-color: $color-grey;

      &:hover {
        .profile-labels__label-tooltip {
          visibility: visible;
        }
      }
    }
  }

  &--profile-card-mini {
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
  }
  &--profile-card {
    padding-left: 12px;
    padding-right: 15px;
  }
  &--adr-book {
    max-width: 50%;
    justify-content: flex-end;
    padding-right: 6px;
  }
  &--contact-det {
    justify-content: center;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
  }
  &--table-item {
    position: absolute;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    bottom: 5px;
    left: 5px;
    width: auto;
  }
}
