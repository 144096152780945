.toggle-switch {
  display: inline-flex;
  align-items: center;
  width: 20px;
  height: 10px;
  cursor: pointer;

  &--disabled {
    cursor: not-allowed;
  }

  &__checkbox {
    opacity: 0;
    width: 0;
    height: 0;
    display:none;
  }
  &__slider {
    display: block;
    padding: 5px;
    border: 1px solid transparent;
    background-color: #ccc;
    transition: .4s;
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 10px;
  }
  &__slider-inner {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: calc(100%);
    width: 50%;
    right: calc(50%);
    background-color: white;
    transition: 0.3s;
    border-radius: 50%;
  }

  &__checkbox:checked + &__slider {
    background-color: green;
  }
  &__checkbox:checked + &__slider  > &__slider-inner {
    right: 0;
  }

}