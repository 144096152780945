@import '../../../../variables.scss'; 

.contacts-merge-btn {
  position: relative;

  &__unread{
    @include unreadIndicator;
    
    position: absolute;
    top: -10px;
    right: -6px;
  }
}