@import "../../../../variables.scss";

.night-bot-modal {
  width: 100vw;
  height: 100vh;
  padding: 5vh 5vw 0;
  font-family: 'Roboto', sans-serif;

  &__chats {
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    height: calc(100% - 120px);
  }

  &__scrollbar-wrapper {
    width: 33%;
    padding-top: 50px;
  }

  &__btn--close {
    position: relative;
    color: #fff;
    padding-left: 15px;
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__btn--close::before, &__btn--close::after {
    content: "";
    position: absolute;
    background-color: #fff;
    border-radius: 6px;
    width: 2px;
    height: 14px;
    left: 0px;
    transform: rotate(-45deg);
  }

  &__btn--close::after {
    transform: rotate(45deg);
  }
}

.session-list {
  border-radius: 6px;
  padding: 0 12px;

  &__caller-name {
    color: #0092f2;
  }

  &__profile-name {
    color: #8b008b;
  }

  &__element {
    background-color: #fff;
    border-radius: 6px;
    list-style: none;
    padding: 5px;
    margin: 15px 0;
    display: flex;
    min-height: 55px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .booking-mini__column {
      display: inline;
      &:nth-child(2) {
        max-width: 50%;
        width: 100%;
      }
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 25%;
    & span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      font-size: 12px;
      padding: 3px;
    }
  }
}