.call-item {
  display: flex;
  align-items: center;
  min-height: 41px;
  position: relative;

  &:hover &__btn--call {
    opacity: 1;
  }

  &__spinner-wrap {
    height: 18px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    img {
      margin-left: 5px;
    }
  }

  &__btn-microphone svg {
    min-width: 20px;
  }

  &--alarm &__main,
  &--transfer &__main {
    border-radius: 5px;
    animation: blink 1s linear infinite;
    background-color: #dcecf8;
  }

  &__main {
    display: flex;
    flex-grow: 1;
    align-items: center;
    transition: all 1s;
    min-width: 0;
    margin-right: 5px;
    position: relative;
    background-color: #f5f2e1;
    border-radius: 5px;
    padding: 2px 5px;
  }
  &__img-wrap {
    width: 32px;
    height: 32px;
    margin-right: 5px;
    cursor: pointer;
  }
  &__img {
    border-radius: 50%;
  }
  &__info {
    flex-grow: 1; // this means that the div will grow in same proportion as the window-size
    flex-shrink: 1; // this means that the div will shrink in same proportion as the window-size
    flex-basis: 0; // this means that the div does not have a starting value as such and will take up screen as per the screen size available for.e.g:- if 3 divs are in the wrapper then each div will take 33%.
    min-width: 0;
    text-align: left;

    &--missed {
      margin-right: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__number {
    margin: 0;
    line-height: 1.5;
    font-size: 12px;
    color: #808080;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__micro-icon {
    position: relative;
    display: inline-block;
    margin-left: 5px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      height: 14px;
      width: 2px;
      right: 5px;
      background-color: red;
    }

    &::before {
      transform: rotate(135deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }
  &__timers {
    display: flex;
    flex-wrap: wrap;
    min-height: 13px;
  }
  &__timer {
    margin-right: 10px;
    font-size: 14px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--hold {
      color: red;
    }
  }
  &__btns {
    position: relative;
    display: flex;
  }
  &__btn {
    &--call {
      display: inline-flex;
      opacity: 0;
      transition: 0.1s all ease-out;
    }
  }
}

.call-item--active {
  transform: translate(0);
  z-index: 6;

  .call-item__main {
    min-width: 290px;
    background-color: #fff;
  }

  .call-item__number {
    font-size: 14px;
  }
  .call-item__timers {
    min-height: 19px;
  }
  .call-item__timer {
    font-size: 17px;
  }
}

.call-item--incoming {
  .call-item__main {
    border: 1px solid #40e49e;
    background-color: #fff;
  }
  .call-item__btn--call {
    opacity: 1;

    &:disabled svg {
      fill: #a5a5a5;
    }
  }
}

.call-item + .player {
  position: relative;
  border-radius: 0 0 6px 6px;
  top: -7px;
  width: calc(100% - 23px);
  border-bottom: solid 1px #c2c2c2;
  border-left: solid 1px #c2c2c2;
  border-right: solid 1px #c2c2c2;

  &::after {
    content: "";
    height: 1px;
    width: calc(100% - 10px);
    background-color: #0092f2;
    display: block;
    position: absolute;
    left: 5px;
    top: 0;
  }
}

@keyframes blink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.96);
  }
  0% {
    transform: scale(1);
  }
}

.call-transfer {
  position: absolute;
  background: #fff;
  z-index: 6;
  top: 30px;
  left: -170px;
  width: 250px;
  box-shadow: 0px 2px 10px #999999;
  padding: 10px;
  font-size: 13px;

  &__title {
    margin-top: 0;
  }
  &__list {
    padding: 0 0 0 15px;
    color: #000;
  }
  &__description {
    width: 200px;
    height: 60px;
    padding: 5px;
  }
  &__footer {
    text-align: center;
  }
  &__btn {
    margin: 0 5px;
  }
}
