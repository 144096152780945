.title {
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
    color: #bdbdbd;
    margin: 10px 0;
    margin-right: 30px;
    min-width: 0px;
    white-space: nowrap;
    position: absolute;
    width: calc(100% - 30px);

    &__text {
      min-width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      display: block;
      margin-right: 5px;
    }
    
    &__icon-wrap {
      width: 15px;
      height: 15px;
      min-width: 15px;
      margin-right: 6px;
    }
  }

  // .calls-info-section__header {
  //   .title {
  //     width: calc(100% - 100px);
  //     margin-right: 90px;
  //   }
    
  //   .calls-info-section__clear-all {
  //     margin-top: 10px;
  //     margin-bottom: 10px;
  //   }
  // }
