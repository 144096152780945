.blinker-wrap {
  cursor: pointer;
  
  .blinker {
    animation: blinker 1.4s ease-out 0.2s infinite;
    opacity: 0.4;
  }
}

@keyframes blinker {
  0% {
    transform: scale(1);
    opacity: 0.4;
  }

  100% {
    transform: scale(6);
    opacity: 0;
  }
}