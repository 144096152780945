.bookings {
  position: absolute;
  left: -300px;
  top: 30px;
  width: 800px;
  padding: 10px 0;
  z-index: 100;
  background-color: #fff;
  font-family: Roboto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  font-size: 14px;
  border-radius: 6px;

  &__btn {
    &--open {
      text-transform: uppercase;
      background-color: #00a562;
      padding: 5px 10px;
      font-size: 14px;
      font-weight: 500;
      font-family: Roboto;
      color: #fff;
      border-radius: 4px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
    }
  }

  &__wrap {
    position: relative;
  }

  &__search-btn {
    position: absolute;
    padding: 5px;
    left: 15px;
    top: 13px;
    font-size: 16px;
  }

  &__tabs-wrap {
    height: 28px;
    min-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    list-style: none;
  }

  &__uncompleted-btn {
    padding: 5px;
    margin: 0;
    position: absolute;
    left: 45px;
    top: 13px;
    font-size: 16px;
    
    &--open {
      left: 15px;
    }
  }

  &__uncompleted-btn-wrap {
    border: 0;
    padding: 0;
  }

  &__tab {
    border: 1px solid;
    border-color: #404040;
    border-left: none;
    color: #404040;
    transition: all 0.2s;
    padding: 6px 10px 4px;
    font-weight: 600;
    cursor: pointer;

    &:nth-of-type(1) {
      border-radius: 4px 0 0 4px;
      border-left: 1px solid #404040;
    }
    &:nth-of-type(3) {
      border-radius: 0 4px 4px 0;
    }

    &:hover {
      background-color: #0092f2;
      color: #fff;
    }

    &.react-tabs__tab--selected {
      background-color: #0092f2;
      color: #fff;

      &:hover {
        background-color: rgb(0, 123, 206);
        border-color: rgb(0, 123, 206);
      }
    }
  }

  &__navigation {
    font-size: 16px;
    padding: 5px 10px;
    transition: color 0.25s;

    &--left {
      margin-right: 10px;
    }
    &--right {
      margin-left: 10px;
    }

    &:hover {
      color: #0092f2;
    }
  }

  &__calendar-wrap {
    position: absolute;
    right: 15px;
    top: 13px;
    color: #0092f2;
    font-size: 16px;

    .calendar {
      right: 0;
      left: auto;
    }
  }

  &__open-calendar {
    margin: 0;
    padding: 5px 5px;
    color: #404040;

    &--active {
      color: #0092f2;
    }
  }

  &__list,
  &__bookings-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__spinner-wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__list-wrap {
    height: 70vh;
    border-top: 1px solid #bfbfbf;
  }

  &__list {
    .booked-editor {
      position: absolute;
      left: -80px;
      top: 53px;
      color: #000;
    }

    &--uncompleted .booked-editor {
      left: 0;
    }
  }

  &__item {
    display: flex;
    position: relative;

    &--next-day {
      background-color: #f3f3f3;
    }
  }

  &__time {
    width: 70px;
    min-width: 70px;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #bfbfbf;
    border-right: 1px solid #bfbfbf;
    color: #000;
    font-weight: 500;
  }

  &__bookings-list {
    flex-grow: 1;
    padding: 10px;
    border-bottom: 1px solid #bfbfbf;
    width: calc(100% - 70px);
    max-width: calc(100% - 70px);
  }

  &__current-time-indicator {
    background-color: red;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    opacity: 0.5;
    z-index: 1;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 2px;
      height: 5px;
      background-color: red;
      top: -2px;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  &__uncompleted-spinner-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__uncompleted-placeholder {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #bfbfbf;
  }
}

.booking-mini {
  background-color: #f6f9fc;
  padding: 5px 5px 2px;
  border-radius: 4px;
  border: 1px solid #f6f9fc;
  color: #000;
  overflow: hidden;
  height: 51px;

  &__price--old {
    text-decoration: line-through;
  }
  &__price--new {
    color: green;
  }

  &--open {
    animation: miniBookingOpening 0.5s;
  }

  &--close {
    animation: miniBookingClosing 0.5s;
  }

  &--outcall {
    border: 1px solid #0092f2;
  }

  &--pre-booking {
    background-color: #f0e6ff;
  }

  &__wrap {
    margin-bottom: 5px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__spinner-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__column {
    display: flex;
    flex-direction: column;
    padding-right: 3px;
    line-height: 16px;
    font-size: 12px;
    text-align: left;

    &:last-child {
      padding-right: 0;
    }

    &:nth-child(1) {
      width: 30%;
    }

    &:nth-child(2) {
      width: 35%;
    }

    &:nth-child(3) {
      flex-direction: row;
      min-width: 50px;
    }

    div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
    }
  }

  &__status-list {
    display: flex;
    flex-direction: column;
    line-height: 1.2;
    margin-right: 3px;
    align-items: flex-end;
    max-width: 100%;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      text-align: right;
    }
  }

  &__name-buttons {
    min-width: 50px;
  }

  &__name {
    font-weight: 500;

    &--girl {
      color: #8b008b;

      svg {
        margin-left: 3px;
        color: #f2cc0c;
        font-size: 10px;
      }
    }

    &--client {
      color: #0092f2;
    }

    &--operator {
      text-align: right;
    }
  }

  &__overdue {
    color: #f8737f;
  }

  &__request {
    color: #0092f2;
  }

  &__unchecked-labels {
    display: flex;
    padding: 2px 0;
    transition: opacity 0.25s;
    min-height: 12px;

    &--hidden {
      opacity: 0;
    }
  }

  &__unchecked-indicator {
    width: 8px;
    min-width: 8px;
    height: 8px;
    background-color: darkcyan;
    border-radius: 100%;
    margin-left: 5px;
  }

  &__labels {
    display: flex;
    flex-wrap: wrap;
  }

  &__label {
    border-radius: 4px;
    border: 1px solid black;
    padding: 2px 5px 2px 20px;
    margin-left: 10px;
    margin-top: 5px;
    position: relative;
    font-size: 12px;
    cursor: pointer;
  }

  &__label-check {
    position: absolute;
    left: calc(1em / 3);
    top: calc(1em / 4);
    width: 1em;
    height: 1em;
    border: 1px solid #000;
    border-radius: 20%;
    pointer-events: none;

    &--active {
      border: none;
    }
  }

  &__btn {
    padding: 0 5px;

    svg {
      pointer-events: none;
    }

    &:last-child {
      margin-right: -5px;
    }
  }

  &__btn-session {
    & * {
      pointer-events: none;
    }
  }

  &__status {
    &--cancelled {
      color: #dc3545;
      cursor: pointer;
    }

    &--pending {
      color: orange;
    }

    &--in-progress {
      color: #f658c0;
    }

    &--finished {
      color: #28a745;
    }
  }

  &__cancel-info {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    top: -5px;
    left: -55px;
    width: 100%;
    height: 32px;
    padding: 1px 5px;
    border-radius: 5px;
    border: 1px solid #bfbfbf;
    background: #fffffa;
    line-height: 1.2;

    &--title {
      font-weight: bold;
      margin-right: 4px;
    }
  }
}

@keyframes miniBookingOpening {
  0% {
    height: 51px;
  }

  100% {
    height: 135px;
  }
}

@keyframes miniBookingClosing {
  0% {
    height: 135px;
  }

  100% {
    height: 51px;
  }
}

.chat {
  .bookings {
    position: unset;
    height: 100%;
    width: 100%;
    z-index: 6;
    right: 0px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 5px;
    left: 0;
    top: 0;

    &__wrap {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 6;
      right: 0px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      border-radius: 5px;
      left: 0;
      top: 0;

      & > div,
      & > div > div,
      .react-tabs__tab-panel--selected {
        height: 100%;
      }
    }
    &__list-wrap {
      height: 100%;
    }
    &__bookings-list {
      width: calc(100% - 40px);
      max-width: calc(100% - 40px);
    }
    &__time {
      width: 40px;
      min-width: 40px;
      font-size: 12px;
    }
    &__tabs-wrap {
      margin-bottom: 25px;
    }
    &__tab {
      padding: 2px 4px;
      font-size: 12px;
    }
    &__navigation--left {
      margin: 0;
    }
    &__navigation--right {
      margin: 0;
    }
    &__calendar-wrap,
    &__uncompleted-btn,
    &__search-btn {
      top: 35px;
    }
  }
}

#dialogs-root {
  .booked-editor--chat {
    transform: translateX(-35%);
    z-index: 10;
  }

  .booked-editor--modal {
    z-index: 1100;
  }
}
