.sidebar {
  min-width: 225px;
  width: 225px;
  height: 100%;

  &__panes {
    // display: flex;
    // flex-direction: column;

  }
  // height: calc(100% - 60px);
  // flex-grow: 0;

  & .Pane1 {
    z-index: 5;
  }

  & .Resizer.horizontal {
    padding-right: 11px;
  }
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 11px 4px 12px;

  &__type {
    display: flex;
    align-items: center;
    color: #808080;	
    font-family: Roboto;
    font-size: 14px;
    
    & > svg {
      margin-right: 5px;
    }
  }
}

.room-tabs-menu{
  position: absolute;
  right: 11px;
}

// .active-call {
//   min-height: 132px;
// }

// .custom-border{
//   border-bottom: 10px solid #BFBFBF;
//   padding: 18px 0;
// }
