.calendar {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  min-height: 430px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  font-family: Roboto;

  &__wrap{
    font-family: Roboto;
    background-color: #fff;
    border-bottom: 3px solid #0092f2;
  }
  &__actions{
    margin-bottom: 24px;
  }
  &__cancel, &__submit{
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 21px;
    text-align: center;
    color: #fff;
    border-radius: 6px;
  }
  &__cancel{
    background-color: #bfbfbf;
    background-color: #bfbfbf;
    padding: 5px 22px;
  }
  &__submit{
    background-color: #01df85;
    padding: 10px 37px;
    margin-left: 30px;
  }

  .react-calendar {
    position: relative;
    top: 0px;
    left: 10px;
    z-index: 1;
    width: 360px;
    max-width: none;
    height: 430px;
    padding-right: 10px;
    border: none;
    font-family: Roboto;

    &__tile {
      font-size: 14px;
      padding: 8px;
      border-radius: 4px;

      &--active {
        background: #0092f2;
        font-weight: bold;

        &:hover {
          background: #0092f2;
        }
        &:focus {
          background: #0092f2;
        }
      }
      &:disabled {
        opacity: 0.9;
        cursor: not-allowed;
      }
    }
    &__month-view__days__day {
      width: 40px !important;
      height: 40px !important;
      max-width: 40px !important;
      margin: 5px;

      &--weekend:not(.react-calendar__tile--active):not(.react-calendar__month-view__days__day--neighboringMonth) {
        color: #000;
      }
      &--weekend[disabled]:not(.react-calendar__month-view__days__day--neighboringMonth){
        color: #808080;
      }
      &--neighboringMonth {
        color: #bfbfbf;
      }
    }
    &__navigation {
      // margin-top: 10px;

      &__label {
        font-size: 14px;
      }
      &__arrow {
        font-weight: 500;
      }
      &__next2-button,
      &__prev2-button {
        display: none;
      }
      &__next-button {
        position: relative;
        color: transparent;

        &::after,
        &::before {
          content: "";
          position: absolute;
          height: 10px;
          width: 2px;
          background-color: #000;
          right: 20px;
        }
        &::after {
          transform: rotate(-45deg);
        }
        &::before {
          transform: rotate(45deg);
          top: 20px;
        }
      }
      &__prev-button {
        position: relative;
        color: transparent;

        &::after,
        &::before {
          content: "";
          position: absolute;
          height: 10px;
          width: 2px;
          background-color: #000;
          right: 20px;
        }
        &::after {
          transform: rotate(45deg);
        }
        &::before {
          transform: rotate(-45deg);
          top: 20px;
        }
      }

      button[disabled] {
        opacity: 0;
        cursor: default;
      }
    }
    &__month-view__weekdays {
      margin-bottom: 20px;
    }
    &__month-view__weekdays__weekday {
      text-decoration: none;
      text-transform: none;
      font-size: 14px;
      font-weight: 400;
      width: 40px !important;
      max-width: 40px !important;
      min-width: 40px !important;
      color: #000;
      margin: 5px;

      abbr {
        text-decoration: none;
      }
    }
  }
}

.calendar-menu {
  position: relative;
  top: 0;
  height: 100%;
  width: 150px;
  background: #fff;
  border-left: 1px solid lightgray;
  margin: 0;
  padding: 10px;
  list-style: none;
  border-radius: 0 4px 4px 0;

  &__item {
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    padding: 5px 5px 5px 20px;
    margin-bottom: 5px;
    border: 1px solid #e4e9ef;
    border-radius: 4px;

    &:hover {
      background-color: #e8ebf1;
    }

    &--active {
      background-color: #0092f2;
      color: white;

      &:hover {
        background-color: #0092f2;
      }
    }
  }
}

// Time
.calendar-time-select {
  position: relative;
  width: 100px;
  background-color: #fff;

  &__input-wrap {
    display: none;
  }
  &__list {
    position: absolute;
    margin: 0;
    padding: 6px 2px 6px 6px;
    top: 0;
    list-style: none;
    z-index: 2;
    border-left: 1px solid lightgray;
    border-top: none;
    width: 100%;
    height: 100%;
    overflow: auto;

    &--hidden {
      display: none;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
  }

  &__option {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px;
    width: 100%;
    text-align: center;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;

     &--focused {
      background-color: #e8ebf1;
      cursor: pointer;
    }
    &--active {
      color: #fff;
      background-color: #0092f2;
    }
  }
}
