.parsed-girls-list {
  height: 100%;
  overflow: hidden;

  &__drop-wrap {
    position: fixed;
    z-index: 7;
    width: 345px;
    height: 550px;
    left: 10px;
    top: 60px;
  }

  &__spinner-wrap {
    position: relative;
    height: 100%;
    img {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &__list-wrap {
    height: calc(100% - 115px);
    margin-top: 5px;
    padding: 10px;
    padding-right: 2px;
  }

  &__load-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__list-container {
    height: 100%;
    position: relative;
  }
  &__list {
    padding: 0;
    margin: 0;
    padding-right: 12px;
  }
  &__no-items{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 14px;
    color: #bfbfbf;
  }

  &__wrap {
    border-radius: 6px;
    box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.534);
    background-color: #fff;
    height: 100%;
  }

  &__input-title {
    color: #5d5f61;
    font-weight: 600;
    margin-bottom: 7px;
    font-size: 16px;
    display: block;
    position: absolute;
    z-index: 1;
    left: 10px;
    top: 15px;
  }
  
  &__input-wrap {
    padding: 40px 10px 20px;
    background-color: #f6f9fc;
    position: relative;
    font-size: 16px;
    svg {
      position: absolute;
      right: 7%;
      bottom: 31%;
      color: #808080;
      cursor: pointer;
    }
  }

  &__search-input {
    width: 100%;
    border: 1px solid #c8c9c9;
    padding: 13px 10px;
    letter-spacing: 0.88px;
    border-radius: 4px;
    outline: none;
    color: #5d5f61;
    font-weight: 600;
    &::placeholder {
      font-weight: 100;
    }
  }


  &__close {
    cursor: pointer;
    position: absolute;
    height: 30px;
    width: 30px;
    z-index: 2;
    right: 3px;
    top: 7px;
  
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 14px;
      height: 20px;
      width: 2px;
      background-color: red;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
}