.video{
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;

  &:hover .video__bottom, &--paused .video__bottom{
    bottom: 0;
  }

  &__play{
    color: #fff;
    font-size: 18px;
  }
  &__bottom{
    position: absolute;
    width: 100%;
    bottom: -46px;
    left: 0;
    transition: bottom .5s;
  }
  &__controls{
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    color: #fff; // FIXME:
    box-shadow: inset 0px -31px 36px -17px rgba(0,0,0,0.75);
    padding: 0 15px;
  }
  &__time{

  }
  &__volume{
    width: 100px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-left: 15px;
  }
  &__volume-btn{
    padding: 0;
    margin: 0;
    margin-right: 10px;
    color: #fff;
    font-size: 18px;
  }
  &__toggle-fullscreen{
    padding: 0;
    margin: 0;
    margin-left: auto;
    color: #fff;
  }

  video {
    height: 100%;
    width: 100%;
    background-color: #000;
  }
}