.countdown-timer {
  height: 100px;
  position: relative;
  
  &__value {
    position: absolute;
    display: flex;
    width: 94px;
    height: 94px;
    justify-content: center;
    align-items: center;
    background: #494949;
    color: white;
    border-radius: 50%;
    font-size: 60px;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 3px;
    z-index: 10;
  }

  &__body {
    width: 100px;
    height: 100px;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    clip: rect(0px, 100px, 100px, 50px);
    opacity: 0.99;

    &--circle {
      width: 100px;
      height: 100px;
      border: 3px solid rgb(0, 255, 0);
      border-radius: 50%;
      position: absolute;
      clip: rect(0px, 50px, 100px, 0px);
      opacity: 0.99;
    }

  }

  div[data-anim~=base] {
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }
  &__body[data-anim~=timer] {
    animation-duration: 0.01s;
    animation-name: closeWrapper;
  }
  &__body--circle[data-anim~=left] {
    animation-name: leftSpin;
  }
  
  &__body--circle[data-anim~=right] {
    animation-name: rightSpin;
  }
}

@keyframes rightSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
@keyframes leftSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes closeWrapper {
  to {
    clip: rect(auto, auto, auto, auto);
  }
}