.chat-creator-button {
  margin-left: auto;
  cursor: pointer;
  transition: 0.15s all;
  display: flex;

  & svg {
    fill:#bdbdbd;
    
    &:hover {
      fill: #0092F2;
    }
  }

  &__text {
    flex-shrink: 0;
    background-color: #0092F2;
    color: #fff;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      background-color: #bfbfbf;
    }
  }

  &:active {
    transform: translateY(1px);
  }
}