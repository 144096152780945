.busy-status {
  position: absolute;
  width: 162px;
  height: 32px;
  border: solid 1px #bdbdbd;
  border-radius: 0 4px 4px 4px;
  background-color: #fff;
  left: 47px;
  top: 5px;
  box-sizing: border-box;
  box-shadow: 3px 0px 5px 1px #00000024;
  font-size: 12px;
  padding: 2px 10px;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    background: linear-gradient(225deg,#fff, #fff 49%, #bdbdbd 49%, #bdbdbd, 52%, transparent 53%);
    border-top: solid 1px #bdbdbd;
    left: -10px;
    top: -1px;
  }

  &__trimer {
    width: 90px;
    height: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    margin: 0;
    padding: 0;
    font-weight: bold;

    &--inline {
      display: inline;
      font-weight: bold;
      padding: 0 4px;
    }
  }

}