.night-bot-report-btn  {
  position: relative;
  padding: 0;

  &__counter {
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid #0092f2;
    color: #000;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    width: 18px;
    height: 18px;
    line-height: 17px;
    font-size: 10px;
    font-family: Roboto;
    position: absolute;
    top: -10px;
    right: -6px;
  }
}