.calls-log {
  margin-left: 15px;

  &__wrapper {
    position: absolute;
    width: 340px;
    height: 520px;
    z-index: 9546;
    top: 42px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);
    padding: 10px;
    font-size: 13px;
    line-height: 1;

    &::after {
      left: 6px;
      right: auto;
      top: -14px;
      border: 7px solid transparent;
      border-bottom-color: #fff;
      content: "";
      display: inline-block;
      position: absolute;
    }
  }

  &__drop {
    margin-left: 4px;
    margin-top: 3px;
  }

  &__load-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  } 

  &__list-container {
    width: 100%;
    height: 100%;
    list-style: none;
  }

  &__list {
    list-style-type: none;
    padding: 0;
  }

  &__no-items{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 14px;
    color: #bfbfbf;
  }

  &__img-wrapper {
    width: 12px;
    height: 12px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    border-radius: 5px;
    background-color: #F6F9FC;
    padding: 6px 10px;
    position: relative;
    margin: 7px 0;
  }
  &__name {
    width: 100px;
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
&__btn {
  display: flex;
}
  &__meta {
    color: #F8737F;
    position: absolute;
    right: 10px;
    font-size: 12px;
  }
  &__dash {
    margin: 0 4px;
  }

  &__date {
    margin-left: 15px;
  }

  &__date, &__duration {
    color: #000;
    font-weight: 400;
    font-size: 10px;
  }
}