.date-indicator {
  position: sticky;
  top: 0;
  z-index: 1;
  
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px auto 10px;
    background-color: rgba(0, 0, 0, 0.48);
    border-radius: 5px;
    padding: 2px 10px;
    font-weight: 100;
    font-size: 11px;
    color: #fff;
  }

  &__icon {
    font-size: 14px;
    margin-left: 10px;
  }
}