.resizable-textarea {
  padding: 5px;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #c2d1d9;
  border-radius: 6px;

  &:focus-within {
    border: 1px solid #0092f2;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-button {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin: 5px;
  }
}
