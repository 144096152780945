.context-menu {
    position: fixed;
    background: white;
    box-shadow: 0px 2px 10px #999999;
    z-index: 3;
    margin: 1px;
    padding: 0;
    list-style: none;
    border-radius: 5px;

  &__item {
    padding: 6px 50px 5px 10px;
    min-width: 160px;
    cursor: default;
    font-size: 12px;

    &:hover {
        background: linear-gradient(to top, #555, #333);
        color: white;
        cursor:pointer;
    }
    &:active {
        color: #e9e9e9;
        background: linear-gradient(to top, #555, #444);
    }
    &--disabled {
        color: #999999;
        pointer-events: none;
    }
  }
  &__separator {
    width: 100%;
    height: 1px;
    background: #CCCCCC;
    margin: 0 0 0 0;
  }
}