.away-modal {
  background-color: #fff;
  padding: 80px;

  &__btn {
    margin: 40px auto 0;
    min-width: 120px;
    min-height: 48px;
    border-radius: 10px;
    padding: 15px 20px;
    letter-spacing: 1px;
    color: #fff;
    background: rgba(30, 144, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: shadow-pulse 1s infinite;
  }
}


@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}