@import "../../variables.scss";

$changeValueColor: green;

.booked-editor {
  position: fixed;
  z-index: 2;
  width: 800px;
  min-height: 170px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  font-family: Roboto;
  font-size: 14px;
  cursor: auto;

  &__pending-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  // &--animated-init {
  //   animation: 0.5s bookedEditorInit;
  // }

  &--opacity {
    opacity: 0;
  }

  &--editing {
    z-index: 3;
  }

  &--parsed-girl-list {
    position: fixed;
    top: 150px;
    left: 50%;
    transform: translateX(-50%) !important;
  }

  // &--fade-out {
  //   animation: bookedZoneFadeOut 0.5s;
  // }

  &__fields {
    display: flex;
    position: relative;
    padding: 10px;
    color: #000;
  }

  &__field {
    text-align: left;
    flex-grow: 1;

    &--duration {
      width: 126px;
      max-width: 126px;
    }

    &--time {
      margin: 0 10px;
    }

    &--type {
      width: 88px;
      max-width: 88px;
      position: relative;
    }

    &--type-error {
      position: absolute;
      font-size: 12px;
      right: -5px;
      padding-top: 6px;
      color: #f8737f;
    }

    &--rate {
      width: 55px;
      max-width: 55px;
    }

    &--name {
      position: relative;
      width: 100px;
      max-width: 100px;
    }

    &--date {
      max-width: 85px;
      width: 85px;
    }

    &--scheduled-time {
      width: 180px;
      max-width: 180px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &--contact {
      width: 120px;
      max-width: 120px;
    }

    &--success {
      flex-basis: 90px;
      flex-grow: 0;
    }

    &--message {
      margin-right: 10px;

      textarea {
        flex-grow: 1;
        outline: none;
        font-size: 14px;
        background: none;
        resize: none;
        height: 50px;
        max-height: 110px;
        width: 100%;
        line-height: 1.2;

        &:disabled {
          border: unset;
        }
      }
    }

    &--booking-success {
      position: relative;
    }

    &--cancel-reason {
      position: absolute;
      width: 150px;
      left: -40px;
      font-size: 12px;
      text-align: center;
      color: #f8737f;
    }
  }

  &__tooltip {
    font-size: 12px;
    padding: 4px 7px;
    background-color: #dc3545;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    top: 13px;
    right: 85px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 5px;
      right: -4px;
      width: 10px;
      height: 10px;
      background-color: #dc3545;
      transform: rotate(45deg);
    }
  }

  &__field-title {
    font-weight: 500;
    margin-bottom: 10px;
    text-align: left;

    &--changed {
      color: $changeValueColor;
    }
  }

  &__field-value {
    margin-right: 5px;
    margin-top: 13px;
    font-style: italic;
  }

  &__field-text {
    padding-right: 5px;
    padding-bottom: 5px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;

    &--changed {
      color: $changeValueColor;
    }
  }

  &__date-string {
    display: block;
    margin-top: 13px;
    text-align: left;

    &--changed {
      color: $changeValueColor;
    }
  }

  &__element-name {
    &--changed {
      color: $changeValueColor;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-around;
  }

  &__additional-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 75px;
    position: relative;

    & > button:nth-of-type(1) {
      margin-bottom: 10px;
    }
  }

  &__additional-btns {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;

    & > button:last-child {
      width: 130px;
      margin-left: 5px;
    }

    & :first-child {
      margin-right: 5px;
    }
  }

  &__cancel-container {
    display: flex;
    position: absolute;
    border: 1px solid #c2d1d9;
    background: #f5f5f5;
    width: 500px;
    height: 120px;
    left: 100px;
    padding: 5px;
    border-radius: 6px;
    z-index: 2;
    // animation: fadeInUp 0.5s;
  }

  &__cancel-reasons {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0 5px;

    &--title {
      font-weight: 500;
      padding: 5px 10px;
      text-align: left;
      font-size: 15px;
    }

    &--label {
      padding: 5px 0;

      input {
        margin-right: 5px;
      }
    }
  }

  &__cancel-actions {
    display: flex;
    width: 50%;
    flex-direction: column;

    &--descr {
      height: 70%;
      padding: 5px 0;

      textarea {
        width: 100%;
        height: 100%;
        padding: 5px;
        border: 1px solid #c2d1d9;
        border-radius: 6px;
        outline: none;
        font-size: 14px;
        resize: none;
        line-height: 1.2;

        &:focus {
          border-color: #80bdff;
        }

        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-track-piece {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: #dbdee4;
          border-radius: 6px;
        }
      }
    }

    &--btns {
      height: 30%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
  }

  &__btn {
    color: #fff;
    min-width: 72px;
    padding: 5px 15px;
    border-radius: 4px;

    &--submit {
      background-color: #00a562;
      margin-bottom: 10px;
    }

    &--danger {
      background-color: #f8737f;
    }

    &--primary {
      background-color: #0092f2;
    }

    &--orange {
      background-color: #ff9900;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &:disabled + .booked-editor__date-string {
      opacity: 0.5;
    }
  }

  &__avatar-img-wrap {
    width: 100px;
    max-width: 100px;
    height: 150px;
    margin-right: 10px;
  }

  &__time-selectors {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .booked-editor-select {
      width: 45px;
    }
    .booked-editor-disabled-select {
      width: auto;
    }
  }
  &__time-separator {
    padding: 0 5px 4px;

    &--disabled {
      padding: 0 2px 5px 0;
    }
  }
  &__price {
    display: flex;
    align-items: center;
    line-height: 1.2em;
    cursor: pointer;
    &--old {
      text-decoration: line-through;
    }
    &--discount {
      color: green;
    }
    input {
      cursor: pointer;
      width: 0.75em;
      margin-right: 1px;
    }
  }
  &__radio {
    display: flex;
    align-items: center;
    line-height: 1.2em;
    cursor: pointer;
    margin-right: 5px;

    &--not-available span {
      margin-left: 5px;
    }

    &--changed {
      > span {
        color: $changeValueColor;
      }
    }
  }

  &__field-container {
    display: flex;
    flex-direction: column;

    &--row {
      display: flex;
    }
    &--date-and-time {
      margin-bottom: 20px;
    }
  }

  &__request {
    position: relative;
    border-top: 1px solid #eee;
    color: #000;

    // &--new {
    //   transition: all 0.5s ease, border 0s ease;
    //   height: 0;
    //   border: none;
    //   overflow: hidden;
    //   padding: 0;
    //   margin-top: 0;
    //   max-height: 160px;
    // }
    // &--open {
    //   padding: 10px 0;
    //   margin-top: 10px;
    //   height: 97px;
    //   border-top: 1px solid #eee;
    // }
  }

  &-services {
    color: #000;
    &__header {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 10px 10px 0;
    }

    &__rates {
      display: flex;
      flex-basis: 170px;
      flex-direction: column;
    }

    &__rate {
      font-weight: bold;
    }

    &__list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0;
      padding: 10px;
      list-style: none;
    }

    &__element {
      width: calc(100% / 3);
      padding: 0 12px;
      label{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px;
        cursor: pointer;
        input {
          margin-right: 5px;
        }
      }
      &--changed {
        color: $changeValueColor;
      }
    }
  }

  &__new-scheduled-msg {
    height: 0;
    overflow: hidden;
    max-height: 160px;
    position: relative;

    &--open {
      border-top: 1px solid #eee;
      height: 100px;
    }

    &--overflow-unset {
      overflow: unset;
    }

    &--transition-unset {
      transition: unset;
    }
  }

  &__sheduled-calendar {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 300px;
    z-index: 1;
  }
}

.booked-editor__user-select {
  &--changed {
    color: $changeValueColor;
  }
}

.booked-editor-select,
.booked-editor-disabled-select {
  position: relative;
  width: 90%;

  &__input-wrap {
    display: flex;
    align-items: center;
    user-select: none;
    border: solid 1px #808080;
    outline: none;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.88px;
    line-height: 19px;
    padding: 2px;
    padding-right: 16px;

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &--changed {
      color: $changeValueColor;
    }
  }

  &__input-value {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__input {
    background: 0;
    border: 0;
    font-size: inherit;
    outline: 0;
    padding: 0;
    width: 1px;
    color: transparent;
    left: -100px;
    opacity: 0;
    position: relative;
    transform: scale(0);
  }

  &__indicator {
    pointer-events: none;
    position: absolute;
    right: 5px;
  }

  &__list {
    position: absolute;
    top: 24px;
    // position: fixed;
    max-width: 114px;
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 2;
    background-color: #f6f6f6;
    border: 1px solid #808080;
    width: 100%;
    max-height: 400px;
    overflow: auto;

    &--hidden {
      display: none;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
  }

  &__option {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px;
    width: 100%;

    &--active {
      color: #0092f2;
    }

    &--focused {
      background-color: #0092f2;
      color: #fff;
      cursor: pointer;
    }
  }
}

.booked-editor-disabled-select {
  &__indicator {
    display: none;
  }

  &__input-wrap {
    padding: 2px;
    border: none;
  }
}

.scheduled-msg {
  min-height: 95px;
  border-top: 1px solid #eee;
  position: relative;
}

@keyframes bookedZoneFadeOut {
  // from {
  //   opacity: 1;
  // }

  // 50% {
  //   opacity: 0;
  //   transform: scale3d(0.3, 0.3, 0.3);
  // }

  // to {
  //   opacity: 0;
  // }
}

@keyframes bookedEditorInit {
  0% {
    height: 0;
    // overflow: hidden;
  }

  99% {
    overflow: hidden;
  }

  100% {
    height: 170px;
  }
}

@keyframes fadeInUp {
  // from {
  //   transform: translate3d(-100%, 0, 0);
  // }
  // to {
  //   transform: translate3d(0, 0, 0);
  // }
}

// 📌️ RequirementCheckbox.js

.requirement-checkbox {
  position: relative;

  display: inline-flex;
  align-items: center;
  padding: 2px 5px;

  cursor: pointer;
  border: 1px solid #000;
  border-radius: 4px;

  &--sticked {
    padding-left: 15px;
  }

  &--highlighted {
    border-color: #ff4500;
    box-shadow: 0px 0px 2px 0px #FF4500;
  }

  &--disabled {
    opacity: 0.6;
    cursor:default;
  }

  &__sticker {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 10px;

    border-radius: 4px 0 0 4px;
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1em;
    height: 1em;
    margin-right: 5px;

    border: 1px solid #000;
    border-radius: 3px;

    &--checked {
      border: none;
    }
  }
}

// 📌️ RequirementCard.js

.requirements-card {
  padding: 10px;

  border-top: 1px solid #eee;

  color: #000;

  &__title {
    margin: 0 0 10px;

    font-weight: 500;
    text-align: center;
  }

  &__placeholder {
    margin: 0;
    
    text-align: center;
  }
}

.requirement-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0;
  margin: -3px -5px;

  list-style: none;

  &__item {
    margin: 3px 5px;
  }
}

// 📌️ RequirementsButton.js

.requirements-button{
  position: absolute;
  left: 0;
  bottom: 0;

  padding: 0;

  text-align: left;

  &__text {
    position: relative;

    display: inline-block;

    font-size: 12px;

    border-bottom: 1px solid #000;

    &--highlighted {
      border-color: #ff4500;

      &::after {
        position: absolute;
        content: '';
        bottom: -1px;
        left: 0;

        width: 100%;
        height: 1px;

        box-shadow: 0px 0px 2px 0px #ff4500;
      }
    }
  }
}

.requirement-status-list {
  display: flex;

  &__item {
    width: 10px;
    height: 10px;

    border-radius: 50%;
    background-color: $color-blue;

    &:not(:last-child) {
      margin-right: 2px;
    }
  }
}
