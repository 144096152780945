.notifications-section {
  height: 100px;
  padding-top: 8px;

  &__wrap {
    display: flex;
    height: 100%;
    align-items: center;
  }
  .notification {
    &__item {
      &--request {
        width: 420px;
        min-width: 420px;
      }
    }

    &__link {
      &--name {
        display: inline-block;
        vertical-align: bottom;
        max-width: 140px;
        cursor: pointer;
      }
    }
    &__request {
      justify-content: center;
    }
  }
}
