@import "../../variables.scss";

.main-page {
  min-height: 100vh;
  background-color: #e6ebf1;
  padding: 0 15px;

  &__container {
    display: flex;
    height: calc(100vh - 60px);
  }
  &__contact-section {
    display: flex;
  }
  &__chats-container {
    height: 100%;
  }
  &__chats {
    display: flex;
    padding: 10px 20px 0;
    justify-content: space-between;
    // flex-grow: 1;
    height: calc(100% - 100px);
    background-color: $color-primary;
  }
  &__notifications {
    position: relative;
    height: 100px;

    &--divider {
      position: absolute;
      width: 100%;
      &:after {
        content: "";
        display: block;
        height: 8px;
        width: 100%;
        background-image: url(/media/separator-horizontal.svg);
        background-size: contain;
        background-repeat: repeat;
      }
    }
  }
}

// .call-modal {
//   border: 1px solid #eee;
//   box-shadow: 0 2px 2px #ccc;
//   background-color: white;
//   padding: 10px;
//   width: 700px;
//   text-align: center;

//   &__img-wrap {
//     width: 150px;
//     height: 150px;
//     margin: 0 auto;
//   }
//   &__name {

//   }
//   &__btns {
//     margin-top: 30px;
//   }
//   &__btn {
//     border: 1px solid #0092F2;
//     border-radius: 4px;
//     outline: none;
//     margin-right: 10px;
//     padding: 6px 16px;
//   }
//   &__tags-list {
//     display: flex;
//     flex-wrap: wrap;
//     list-style:none;
//     margin: 10px 0;
//     padding: 0;
//     justify-content: center;
//   }
// }

// .voicemails {
//   width: 400px;
//   border: 1px solid #eee;
//   box-shadow: 0 2px 2px #ccc;
//   background-color: white;
//   padding: 10px;
//   text-align: center;
//   background-color: #e6ebf1;

//   &--pending {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: #BFBFBF;
//     font-size: 13px;
//   }

//   &__spinner {
//     width: 50px;
//     height: 50px;
//   }
//   &__wrap {
//     height: 420px;
//   }
//   &__list {
//     list-style: none;
//     padding: 0 10px 0 0;
//     margin: 0;
//   }
// }

.tag {
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 2px 6px;
  font-size: 13px;
  color: #fff;
}

// .contact-form {
//     border: 1px solid #eee;
//     box-shadow: 0 2px 2px #ccc;
//     background-color: #fff;
//     padding: 10px;
//     width: 700px;
//     text-align: left;

//     &__lock {
//       height: 33px;
//       width: 33px;
//       position: relative;
//       overflow: hidden;
//       margin-left: 100px;
//     }

//   &__header {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }
//   &__title {
//     margin: 10px 0;
//   }
//   &__img {
//     width: 150px;
//     height: 150px;
//     margin-top: 10px;
//     overflow: hidden;
//   }
//   &__label {
//     font-size: 12px;
//     white-space: nowrap;
//     flex-basis: 80px;
//   }
//   &__group {
//     margin-bottom: 14px;
//     position: relative;
//     display: flex;
//     align-items: center;

//     &--unflex {

//     }
//   }
//   &__group-wrap {
//     padding-left: 80px;

//   }
//   &__input {
//     // width: 100%;
//     flex-grow: 1;
//     padding: 7px 12px;
//     font-size: 12px;
//     color: #555;
//     background-color: #fff;
//     border: 1px solid #ccc;
//     border-radius: 4px;
//     box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
//     transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
//   }
//   &__btn {
//     color: #fff;
//     background-color: #5bc0de;
//     border-color: #46b8da;
//     padding: 6px 12px;
//     font-size: 14px;
//     font-weight: 400;
//     border: 1px solid transparent;
//     border-radius: 4px;
//     cursor: pointer;
//     outline: none;

//     &--pin {
//       margin: 0 10px;
//     }

//     &--lg {
//       width: 100%;
//       padding: 10px 12px;
//     }
//     &--block {
//       display: block;
//       margin-left:80px;
//       margin-bottom: 10px;
//     }
//     &--del {
//       -position: relative;
//       background: none;
//       border: none;
//       cursor: pointer;
//       padding: 10px;

//        &::before, &::after {
//         content: '';
//         position: absolute;
//         top: 8px;
//         height: 15px;
//         width: 2px;
//         background-color: red;
//       }
//        &::before {
//         transform: rotate(45deg)
//       }

//       &::after {
//         transform: rotate(-45deg)
//       }
//     }
//     &:disabled {
//       cursor: not-allowed;
//     }
//   }
// }

// .ringtones {
//   &--pending {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
//   &__spinner {
//     width: 100px;
//     height: 100px;
//   }
//   &__wrap {
//     height: 420px;
//   }
//   &__list {
//     list-style: none;
//   }
//   &__footer {
//     margin: 10px 0 0 40px;
//     vertical-align: center;
//   }
//     &__alert {
//     text-align: center;
//     padding: 8px;
//     margin-left: 10px;
//     background: lightgreen;
//     color: #155724;
//     background-color: #d4edda;
//     border-color: #c3e6cb;
//     border-radius: 5px;
//     font-size:12px;
//   }
// }

// .ringtone {
//   &__content {
//     display: flex;
//     align-items: center;
//   }
//   &__title {
//     margin: 5px 0;
//   }
//   &__input {
//     margin-left:10px;
//     cursor:pointer;
//   }
// }

// .contact-card {
//   box-shadow: 0 2px 2px #ccc;
//   background-color: #fff;
//   position: relative;
//   border: 1px solid #eee;
//   padding: 10px;

//   &__wrap{

//     .react-tabs{
//       background-color: #fff;
//       height: 80vh;
//       width: 700px;
//       display: flex;

//       &__tab-list{
//         display: block;
//         margin: 0;
//         width: 120px;
//         padding: 2px;
//       }
//       &__tab{
//         border-radius: 0px;
//         margin: 0;
//         width: 100%;
//         font-size: 13px;
//         font-family: Roboto;
//         text-align: left;
//         border: none;
//         background-color: #F6F9FC;
//         border: 1px solid #F6F9FC;
//         border-radius: 6px;
//         color: black;
//         margin-bottom: 5px;

//         &--selected{
//           border: 1px solid black;
//           background-color: #fff;
//         }
//       }
//       &__tab-panel{

//         &--selected{
//           height: 100%;

//           & > *, .contact-form {
//             width: 580px;
//             border: none;
//             box-shadow: none;
//             height: 100%;
//           }
//           & .contact-form{
//             margin-bottom: 40px;
//           }
//         }
//       }
//     }
//   }
//   &__tabs{
//     padding: 10px;
//     margin: 0;
//     list-style: none;
//     border-right: 1px solid #555;
//   }
//   &__tab{
//     background-color: #fff;
//     margin-bottom: 5px;
//     border-radius: 6px;
//     font-size: 14px;
//     font-weight: bold;
//     text-align: left;
//     padding: 3px 18px;
//     border: 2px solid #fff;
//     cursor: pointer;

//     &--active{
//       border: 2px solid #0092F2;
//     }
//   }
//   &__header {
//     min-height: 150px;
//   }
//   &__img-wrap {
//     margin: 0 auto;
//     width: 150px;
//     height: 150px;
//     cursor: pointer;
//   }
//   &__main {

//   }
//   &__title {

//   }
//   &__group {
//     display: flex;
//     align-items: center;
//     line-height: 1.5
//   }
//   &__tags {
//     list-style: none;
//     display: flex;
//     margin: 0;
//     padding: 0;
//     flex-wrap: wrap;
//   }
//   &__label {
//     min-width: 135px;
//     text-align: left;
//     font-weight: bold;
//     padding: 5px 0;
//     white-space: nowrap;
//   }
//   &__value {
//     text-align: left;
//     font-size: 13px;
//   }
// }

// .conversation-media {
//   border: 1px solid #eee;
//   box-shadow: 0 2px 2px #ccc;
//   background-color: #fff;
//   padding: 10px;
//   width: 500px;
//   height: 465px;
//   overflow-y: auto;

//   &__group{

//   }
//   &__links {
//     text-align: left;
//   }
//   &__link {
//     display: block;
//   }
//   &__images {
//     display: flex;
//     flex-wrap:wrap;
//   }
//   &__img-wrap {
//     width: 92px;
//     height: 70px;
//     cursor: pointer;
//     display: -webkit-flex;
//     display: flex;
//     text-align: center;
//     margin: 5px 0;
//   }
//   &__btn {
//     color: #fff;
//     background-color: #5bc0de;
//     border-color: #46b8da;
//     padding: 6px 12px;
//     font-size: 14px;
//     font-weight: 400;
//     border: 1px solid transparent;
//     border-radius: 4px;
//     cursor: pointer;
//     outline: none;
//   }
//   &__spinner {
//     margin: 170px auto 0;
//     width: 100px;
//   }
// }

// .block-form {
//   border: 1px solid #eee;
//   box-shadow: 0 2px 2px #ccc;
//   background-color: #fff;
//   padding: 10px;
//   max-width: 700px;
//   -max-height: 460px;

//   &__title {}
//   &__group {}
//   &__input {
//     display: block;
//     width: 100%;
//     height: 80px;
//     padding: 6px 12px;
//     font-size: 14px;
//     line-height: 1.42857143;
//     color: #555;
//     background-color: #fff;
//     background-image: none;
//     border: 1px solid #ccc;
//     border-radius: 4px;
//     box-shadow: inset 0 1px 1px rgba(0,0,0,.075);

//     & ~ .invalid-feedback {
//       bottom: unset;
//       left: unset;
//     }
//   }
//   &__btn {
//     color: #fff;
//     background-color: #5bc0de;
//     border-color: #46b8da;
//     padding: 6px 12px;
//     font-size: 14px;
//     font-weight: 400;
//     border: 1px solid transparent;
//     border-radius: 4px;
//     cursor: pointer;
//     outline: none;
//     width: 100%;
//     margin-top: 15px;
//   }
// }

.radio-btn__input {
  margin-right: 5px;
}

.hidden {
  display: none;
}

.round-img {
  border-radius: 50%;
  background: #fff;
}

.blocked {
  &::before {
    content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' enableBackground='new 0 0 510 510' version='1.1' viewBox='0 0 510 510' width='32' height='32' fill='red'><path d='M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z M51,255c0-112.2,91.8-204,204-204 c45.9,0,89.25,15.3,124.95,43.35l-285.6,285.6C66.3,344.25,51,300.9,51,255z M255,459c-45.9,0-89.25-15.3-124.95-43.35 L415.65,130.05C443.7,165.75,459,209.1,459,255C459,367.2,367.2,459,255,459z' /></svg>");
    display: block;
    position: absolute;
    z-index: 1;
  }
}

.blocked img {
  opacity: 0.8;
}

// SplitPane styles
.SplitPane {
  position: static !important;
  padding-left: 11px;
  // min-height: 0 !important;
  // height: unset !important;
  // height: 100%;
}
.Pane1 {
  // z-index: 3;
}

.pane--close {
  max-height: 40px !important;

  & + .Resizer.horizontal {
    display: none;
    // cursor: unset;
    // background: none;
  }

  // & + .Resizer.horizontal:before {
  //   background: none;
  // }
}

.Resizer.horizontal {
  background-color: #e6ebf1;
  z-index: 1;
  position: relative;
  height: 16px;
  cursor: row-resize;
  width: 100%;
  margin: -5px auto;
  margin-top: 2px;

  &:before {
    content: "";
    padding: 0 9px;
    background: #e6ebf1;
    display: block;
    position: absolute;
    left: calc(50% - 18px);
    background-image: url(/media/resize-icon.svg);
    background-size: 30px 12px;
    background-position: center;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
  }
  &:after {
    content: "";
    display: block;
    height: 8px;
    width: 100%;
    background-image: url(/media/separator-horizontal.svg);
    background-size: contain;
    background-repeat: repeat;
  }
}

// aside .Resizer.horizontal {
//   margin-top: 26px;
// }

// .Resizer.vertical {
//   width: 11px;
//   margin: 0 -5px;
//   border-left: 5px solid rgba(255, 255, 255, 0);
//   border-right: 5px solid rgba(255, 255, 255, 0);
//   cursor: col-resize;
// }
// .Resizer.vertical:hover {
//   border-left: 5px solid rgba(0, 0, 0, 0.5);
//   border-right: 5px solid rgba(0, 0, 0, 0.5);
// }
// .Resizer.disabled {
//   cursor: not-allowed;
// }
// .Resizer.disabled:hover {
//   border-color: transparent;
// }

.Resizer.vertical {
  background-color: #e6ebf1;
  z-index: 1;
  position: relative;
  cursor: ew-resize;
  min-width: 8px;
  height: 100%;
  margin-left: 8px;
  background: url(/media/separator-vertical.svg);
  background-size: contain;

  &:before {
    content: "";
    padding: 0 9px;
    background: #e6ebf1;
    display: block;
    position: absolute;
    left: calc(50% - 16px);
    top: 250px;
    background-image: url(/media/resize-icon.svg);
    transform: rotate(90deg);
    background-size: 30px 12px;
    background-position: center;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
  }
}
.right-sidebar {
  height: calc(100vh - 60px);

  .sidebar {
    height: auto;

    &:nth-of-type(2) {
      margin-bottom: 5px;
    }
  }
}
