$color-lightprimary: #f6f9fc;
$color-primary: #e6ebf1;
$color-blue: #0092f2;
$color-darkblue: #1d1f7a;
$color-mediumpurple: #9370dbff;
$color-lightgreen: #01df85;
$color-green: #00a562;
$color-yellow: #f2cc0c;
$color-lightpink: #f8737f;
$color-pink: #ff4052;
$color-lightgrey: #bfbfbf;
$color-grey: #808080;
$color-darkgrey: #404040;
$color-orange: #ff9900;
$color-white: #fff;
$color-red: #f01e1e;
$color-black: #000;
$color-red-orange: #FF3D2B;

@mixin scrollBars($color: #d8d8d8) {
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-button {
        height: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: $color;
        border-radius: 3px;
        margin: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: darken($color: $color, $amount: 5%);
    }
}

@mixin hoveredButton ($indent: 9, $backgroundOpacity: .08, $zIndex: -1, $borderRadius: 50%) {
    position: relative;
    z-index: 0;

    & svg {
      opacity: .6;
      transition: opacity .15s cubic-bezier(0.4, 0.0, 0.2, 1);
    }

    &:disabled {
      cursor: default;
    }

    &:not(:disabled):hover {
      svg {
        opacity: 1;
      }

      &:before {
        opacity: 1;
        transform: scale(1);
        background-color: rgba(#404040, $backgroundOpacity);
      }

      &:active:before {
        opacity: 1;
        transform: scale(1);
        background-color: rgba(#000, $backgroundOpacity);
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: -$indent + px;
      left: -$indent + px;
      right: -$indent + px;
      bottom: -$indent + px;
    }

    &:before {
      content: '';
      position: absolute;
      top: -$indent + px;
      left: -$indent + px;
      right: -$indent + px;
      bottom: -$indent + px;
      display: block;
      opacity: 0;
      box-sizing: border-box;
      border-radius: $borderRadius;
      background: none;
      transition-property: transform, opacity;
      transition-duration: .15s;
      transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
      transform: scale(0);
      z-index: $zIndex;
    }
}

@mixin unreadIndicator {
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid $color-blue;
    color: #000;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    width: 18px;
    height: 18px;
    line-height: 17px;
    font-size: 10px;
    font-family: Roboto;
}
