@import "../../variables.scss";

.tel-select,
.email-select,
.empty-tel-select {
  position: relative;

  &__input-wrap {
    display: flex;
    align-items: center;
    user-select: none;
    padding: 3px 15px 3px 3px;
    border-radius: 2px;
    outline: none;
  }

  &__input-value {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__input {
    background: 0;
    border: 0;
    font-size: inherit;
    outline: 0;
    padding: 0;
    width: 1px;
    color: transparent;
    left: -100px;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  &__indicator {
    pointer-events: none;
    position: absolute;
    right: 0;
  }
  &__list {
    position: absolute;
    border: 1px solid green;
    margin: 0;
    padding: 0;
    top: 25px;
    list-style: none;
    z-index: 2;
    background-color: #fff;
    width: 100%;

    &--hidden {
      display: none;
    }
  }
  &__option {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 2px 5px;
    width: 100%;

    &--active {
      color: rgb(33, 72, 243);
    }

    &--focused {
      background-color: aliceblue;
      cursor: pointer;
    }

    &--action-btn {
      padding: 2px 5px;
      width: 100%;
      text-align: center;
      font-size: 11px;
      color: $color-blue;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        color: $color-darkblue;
      }
    }
  }
}

.empty-tel-select {
  color: #f8737f;
}
