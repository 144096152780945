.burger-menu {
    &__toggler {
        height: 15;
        padding: 0;
        background: none;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg{
            pointer-events: none;
        }
    }
}