.full-screen {
  background-color: #000001d3;
  height: 96px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  padding: 20px 28px;
  width: 652px;
  position: relative;

  .call-item {
    width: 220px;

    & > div:last-child > button > svg {
      filter: invert(100%);
    }

    .dropdown-menu {
      color: #000;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__end {
    position: absolute;
    top: -40px;
    font-size: 13px;
    color: #808080;
    right: 16px;
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      position: absolute;
      right: -10px;
      height: 15px;
      width: 2px;
      background-color: #0092f2;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }

  &__button {
    margin: 0;
    flex-grow: 1;
    min-width: 80px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #808080;
    font-size: 14px;
    margin: 0 10px;

    &--start-chat {
      flex-direction: row;
      background-color: #0092f2;
      color: #fff;
      border-radius: 8px;
      padding: 5px 16px;
      width: 120px;
      margin-left: 40px;

      & svg {
        margin-left: 4px;
      }
    }

    &:first-child {
      margin-left: 26px;
    }

    &-name {
      margin-top: 8px;
    }
  }
}
