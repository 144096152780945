.video-thumbnail {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: .35;
    z-index: 1;
  }

  video {
    height: 100%;
  }

  img {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__duration {
    position: absolute;
    right: 3px;
    bottom: 1px;
    z-index: 2;
    color: #fff;
    font-size: 14px;
    font-family: Roboto;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    svg {
      position: relative;
      left: 1px;
    }
  }
}