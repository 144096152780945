.room-info-card {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 12px 10px 8px;
  background-color: #fff;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  height: 80px;

  &__participants {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    display: flex;
    align-items: center;
    overflow: hidden;
    z-index: 19;
    position: absolute;
    top: 55px;
    max-width: calc(100% - 20px);
    overflow-x: scroll;
    padding-bottom: 4px;
    cursor: default;

    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-track-piece {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #dbdee4;
      border-radius: 6px;
    }

    & > li {
      background-color: #00a562;
      color: #fff;
      padding: 1px 5px;
      border-radius: 4px;
      margin: 0 2px;

      &:first-child {
        margin-left: 0;
      }
    }

    & > li[data-isonline="online"] {
      background-color: #00a562;
    }
    & > li[data-isonline="offline"] {
      background-color: #bdbdbd;
    }
    & > li[data-isonline="busy"] {
      background-color: #f8737f;
    }
  }

  & .create-room-dialog__list-wrap {
    height: calc(100%) !important;
  }

  & .create-room-dialog__list-container {
    // height: 556px !important;
  }

  &__information {
    margin-left: 10px;
  }

  &__header {
    width: 100%;
  }

  &__photo {
    position: absolute;
    font-size: 11px;
    word-wrap: break-word;
    width: 120px;
    top: 55px;
    left: calc(50% - 60px);
    letter-spacing: 0.2px;
    cursor: pointer;
    color: #0092f2;
    opacity: 0;
    animation: reveal 0.4s cubic-bezier(0.19, 0.65, 0.46, 1) forwards;
    visibility: hidden;

    &::after {
      content: "Choose group photo";
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      visibility: visible;
      // width: 200px;
      height: 20px;
      top: -5px;
      left: 0;
    }

    &:active {
      color: #bdbdbd;
    }
  }

  &__btn {
    &--details,
    &--close {
      position: absolute;
      right: 0;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      cursor: pointer;
      border-radius: 50%;
      padding: 0;
    }
  }

  &__ava-wrap {
    width: 32px;
    height: 32px;
    margin: 0;

    &--changeble {
      cursor: pointer;
    }
  }

  &__meta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 50px);

    &-wrp {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      width: 100%;
    }
  }

  &__name {
    color: #bfbfbf;
    word-wrap: break-word;
    border-bottom: 3px solid transparent;
    display: inline-block;

    &--input {
      border: none;
      border-bottom: solid 1px #0092f2;
      outline: none;
      width: 100%;
    }
    &--operator {
      color: #000;
      margin-left: 10px;
    }

    &--action {
      position: absolute;
      right: 0;
      width: 30px;
      height: 30px;
    }

    &--action-adm {
      position: absolute;
      right: 10px;
    }
  }
  &__count {
    font-size: 11px;
    word-wrap: break-word;
    letter-spacing: 0.2px;
  }

  &__section {
    background-color: #fff;
    position: absolute;
    width: 100%;
    left: 0;
    top: 80px;
    height: calc(100% - 80px);
    border-radius: 0 0 5px 5px;
    animation: bounceInDown 0.4s cubic-bezier(0.19, 0.65, 0.46, 1) forwards;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 1;
  }

  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn--sbmt {
    color: #fff;
    background-color: #0092f2;
    border-radius: 5px;
    align-self: center;
    font-size: 13px;
    width: 100%;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    margin: 0;
    padding: 10px 0px;

    &:disabled {
      background-color: #bdbdbd;
      cursor: not-allowed;
    }

    &:active {
      transform: translateY(1px);
    }
  }

  &__add-wrap {
    background-color: #f6f6f8;
    width: 100%;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }

  &-add-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    &:active {
      transform: scale(0.96);
    }
  }

  &__list {
    margin: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    list-style: none;
  }

  &__list-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 48px;
    width: 100%;
    position: relative;
  }

  .create-room-dialog__list-container {
    // height: 556px;
  }
  .create-room-dialog {
    &--add-mode {
      height: 100%;
      top: 0;
      left: 0;
      width: 100%;
      box-shadow: none;
      animation: bounceInDown 0.4s cubic-bezier(0.19, 0.65, 0.46, 1) forwards;
    }

    &__wrap {
      height: 100%;

      .create-room-dialog {
        height: calc(100% - 31px);
        overflow: hidden;
      }
    }

    &__header {
      display: none;
    }

    &__btn--sbmt {
      color: #fff;
      background-color: #0092f2;
      border-radius: 5px;
      align-self: center;
      font-size: 13px;
      width: calc(100% - 20px);
      line-height: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      user-select: none;
      margin: 0;
      padding: 10px 0px;
      margin: 10px;

      &:disabled {
        background-color: #bdbdbd;
        cursor: not-allowed;
      }

      &:active {
        transform: translateY(1px);
      }
    }
  }
}

@keyframes bounceInDown {
  0% {
    opacity: 0;
    z-index: 1;
    transform: translate3d(0, -100px, 0);
  }

  to {
    opacity: 1;
    z-index: 22;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
