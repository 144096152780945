@import "../../variables.scss";

.player{
  width: 100%;
  height: 30px;
  background-color: white;
  border-radius: 6px;
  display: flex;
  align-items: center;

  &__audio-source{
    width: 0;
    height: 0;
  }
  &__spinner{
    height: 30px;
    width: 30px;
    margin: auto;
  }
  &__control{
    cursor: pointer;
    position: relative;
    width: 10%;
    height: 100%;
    max-width: 25px;

    &--play{
      position: relative;

      &::after{
        content: "";
        cursor: pointer;
        position: absolute;
        border: 15px solid transparent;
        border-left: 25px solid $color-grey;
        transform: scale(0.6);
      }
    }
    &--pause{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 5px;

      &::after{
        content: "";
        display: block;
        width: 70%;
        height: 60%;
        background: linear-gradient(to right,
        $color-grey 0% 40%,
        transparent 40% 60%,
        $color-grey 60% 100%);
      }
    }
    &--volume{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 25%;
      max-width: none;
      margin-right: 5px;
      user-select: none;

      .player__slider{
        margin-left: 5px;
      }
    }
  }
  &__time{
    font-size: 12px;
    font-family: Roboto;
    color: $color-grey;
    max-width: 25px;
    margin: 0px 10px;
  }
  &__slider{
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    flex-grow: 1;
    user-select: none;
  }
  &__slider-left-path{
    height: 5px;
    background-color: $color-blue;
    border-radius: 6px;
    cursor: pointer;
  }
  &__slider-right-path{
    flex-grow: 1;
    height: 5px;
    background-color: $color-lightgrey;
    border-radius: 6px;
    cursor: pointer;
  }
  &__thumb{
    position: absolute;
    width: 15px; height: 15px;
    background-color: $color-blue;
    border-radius: 100%;
    cursor: pointer;
  }
}