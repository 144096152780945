@import "../../variables.scss";

.profile-rates {
  position: absolute;
  z-index: 183;
  width: 370px;
  right: -30px;
  top: 0px;
  border-bottom: 2px solid $color-blue;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 6px;
  font-family: Roboto;
  background-color: $color-lightprimary;
  cursor: default;

  &__title {
    background-color: $color-lightprimary;
    border-radius: 6px 6px 0 0;
    color: $color-grey;
    font-size: 16px;
    font-weight: 500;
    padding: 15px 20px;
  }
  &__edit-btn {
    position: absolute;
    top: 2px;
    right: 2px;
    padding: 5px;
    font-size: 14px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
  }
  &__header {
    background-color: $color-white;
    color: $color-grey;
    font-size: 12px;
    display: flex;
    padding: 20px 0px 20px 20px;
  }
  &__header-item {
    text-transform: uppercase;
    text-align: center;
    width: 25%;

    &--secret {
      color: $color-red;
    }
    &--member {
      color: $color-green;
    }
    &:first-of-type {
      text-align: left;
    }
  }
  &__items-wrap {
    background-color: $color-lightprimary;
    border-radius: 0 0 6px 6px;
    display: flex;
    justify-content: space-between;
  }
  &__items {
    margin: 0;
    padding: 0 0 0 20px;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    color: $color-black;
    background-color: $color-lightprimary;
    border-radius: 0 0 6px 6px;

    &:first-of-type {
      text-align: left;
      font-size: 12px;
      color: $color-grey;
    }

    // &__items{
    //   margin: 0;
    //   padding: 0;
    //   width: 33%;
    //   text-align: center;
    //   font-size: 12px;
    //   color: #808080;

    //   &:first-of-type{
    //     text-align: left;
    //     font-weight: bold;
    //     font-size: 14px;
    //     color: #000;
    //   }
    //   &:last-of-type{
    //     text-align: right;
    //     font-weight: bold;
    //     font-size: 14px;
    //     color: #000;
    //   }
  }
  &__item {
    display: flex;
    font-size: 12px;
    text-align: center;
  }
  &__cell {
    display: flex;
    width: 25%;
    font-size: 10px;
    padding-bottom: 10px;
    color: $color-black;
    font-weight: lighter;
    border-radius: 0 0 6px 0;

    &-value {
      width: 50%;
    }
    &--name {
      text-align: left;
      font-size: 11px;
    }
    &--incall-outcall {
      background-color: $color-white;
    }
    &--member {
      color: $color-green;
    }
    &--secret {
      background-color: $color-white;
      color: $color-red;
    }
  }
  &__discount {
    color: green;
  }
}
