.tri-state-switch{
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 9px;
  transition: all 0.4s ease-in-out;

  &::before{
    content: "";
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    transition: .4s;
  }
  
  &--off{
    background-color: #f8737f;

    &::before{
      right: 13px;
    }
  }

  &--waiting{
    background-color: #c4c4c4;

    &::before{
      right: 7px;
    }
  }

  &--available{
    background-color: #00A562;

    &::before{
      right: 1px;
    }
  }

  input{
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;
  }

  label{
    padding: 6px 4px;
    transition: all 0.4s ease-in-out;
  }

  label:hover{
    cursor: pointer;
  }
}