@import "../../../../variables.scss";

.profile-carousel {
  width: 100vw;
  height: 75vh;
  position: relative;

  &__img-wrap{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;
    opacity: 0;

    &--active{
      pointer-events: all;
      opacity: 1;
    }
  }
  &__img, .lazy-load__error{
    width: max-content;
    transition: opacity .5s;
    object-fit: contain;
    margin: auto auto 0px;
    height: auto;
  }
  &__prev-slide, &__next-slide{
    position: absolute;
    bottom: 0px;
    width: 250px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    z-index: 2;
    border-radius: 100%;

    &:hover{
      background-color: rgba(236, 226, 226, 0.3);
    }
  }
  &__prev-slide{
    left: calc(50% - 250px);
  }
  &__next-slide{
    transform: rotate(180deg);
    left: 50%;
  }
}