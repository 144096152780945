.notifications-volume {

  &__title{
    text-align: left;
    margin: 0 0 10px;
    padding: 0;
    color: #404040;
    font-size: 12px;
    letter-spacing: 1px;
  }
}