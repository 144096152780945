// @import 'variables';

.dropdown-menu {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 12px rgba(27,31,35,.15);
  position: absolute;
  top: 103%;
  padding: 10px 0;
  z-index: 100;
  // color: green;
  // font-family: $Montserrat;
  line-height: 36px;
  width: 200px;

  font-family: Roboto;	
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 1px;
  color: #24292e;

  &--right {
    right: 0;
    text-align: right;
  }

  &--center {
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  &--fixed, &--fixed-center {
    position: fixed;
    top: unset;
  }

  &--fixed-right {
    position: fixed;
    top: unset;
    transform: translateX(calc(-100% + 15px));
  }

  &--top {
    top: unset;
    bottom: 103%;
  }

  .dropdown-menu__submenu>& {
    position: static;
  }

  &__header {
    margin-top: -12px;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    $item: &;
    position: relative;
    padding: 0 10px;
    text-align: center;

    &--disabled {
      pointer-events: none;
    }

    &:hover {
      background: #F6F6F8;
      cursor: pointer;
    }

    &-btn {
      width: 100%;
      min-width: 140px;
      // display: flex;
      align-items: center;
      white-space: nowrap;
      font-size: inherit;
      line-height: inherit;
      background: none;
      border: none;
      color: inherit;
      border-bottom: 1px solid #BFBFBF;
      letter-spacing: 1px;

      &:active,
      &:focus {
        // color: #e9e9e9;
        background: #fff;
        outline: none;
      }

      #{$item}--disabled & {
        color: red;
        cursor: not-allowed;
      }

      #{$item}--right & {
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }

    &-icon {
      display: flex;
      height: 33px;
      width: 33px;
      margin: 0 10px 0 -10px;
      overflow: hidden;
      border-radius: 50%;

      #{$item}--right & {
        overflow: unset;
        height: 18px;
        width: 18px;
        margin: 0 0 0 10px;
      }

      #{$item}--disabled & {
        filter: grayscale(1);
      }
    }
  }

  &__submenu {
    position: absolute;
    top: 0;
    right: 100%;
  }
}
