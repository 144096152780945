@import "../../../../../variables.scss";

.ii-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  background: transparent;
  font-size: 14px;
  position: absolute;
  right: 0;
  top:0;

  &__item {
    padding: 2px 5px;
    margin: 0 2px;
    cursor: pointer;
    position: relative;
    background-color: #0092F2;
    border-radius: 0px 6px 6px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover .ii-menu__tooltip {
      visibility: visible;
      background-color: #0092F2;
      color: #fff;
    }

    &--danger {
      background-color: $color-pink;
    }
  }
  
  &__btn {
    color: #fff;
    padding: 0;
    font-size: 12px;
  }

  &__tooltip {
    visibility: hidden;
    padding: 3px 5px;
    font-size: 12px;
    background-color: #0092F2;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    bottom: 22px;
    left: -30px;
    z-index: 1;

    &::after {
      position: absolute;
      display: inline-block;
      left: 32px;
      bottom: -12px;
      border: 7px solid transparent;
      border-top-color: #0092F2;
      content: "";
    }
  }

  &__status {
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    padding: 8px 0;
    border: 1px solid red;
    background: lightgreen;
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    border-radius: 5px;
    font-size: 12px;
    z-index: 2;
    // max-height: 35px;
    width: 80px;
  }
}

.ii-sub-menu {
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 5px;
  border-radius: 5px;
  width: 130px;
  right: 0;
  top:17px;
  background: #fff;
  color: #808080;
  z-index: 2;
  text-align: left;
  box-shadow: 0 0 12px rgba(27,31,35,.15);

  &--glossary {
    color: #0092F2;
    width: 230px;
    padding: 5px 0 10px 0;

    .ii-sub-menu__item {
      border: none;
      position: relative;
      padding: 12px 12px 12px 20px;

      &:hover{
        background-color: #f6f6f6;
        color: #0092F2;
      }

      &--disabled {
        background: #c591913b;
        cursor: not-allowed;

        &:hover {
          background: #c591913b;
        }
      }

      &::before {
        content: "";
        background: #BFBFBF;
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 75%;
      }

      &:nth-child(1) {
        &::after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #01df85;
          left: 10px;
        }
      }

      &:nth-child(2) {
        &::after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #f8737f;
          left: 10px;
        }
      }
    }
  }
  
  &__item {
    padding: 12px 5px;
    text-align: center;
    font-size: 12px;
    font-family: Roboto;
    letter-spacing: 1px;
    font-weight: 300;
    border-bottom: 1px solid #BFBFBF;

    &:hover {
      background-color: #f6f6f6;
      color: #000;
    }
  }
}


//  this part for last message in chat 
//  to fit in viewport of timeline in all possible cases. 

//   .timeline__group:last-of-type .interaction--girls:last-of-type .ii-sub-menu {
//     top: unset;
//     bottom: 18px;
//   }
//   .timeline__group:last-of-type .interaction--girls:last-of-type .ii-menu__tooltip {
//     bottom: unset;
//     top: 22px;

//     &::after {
//       bottom: unset;
//       top: -12px;
//       border-top-color: transparent;
//       border-bottom-color: #0092F2;
//     }
//   }

// //  this part for last message in chat 
// //  to fit in viewport of timeline in all possible cases. 

//   .timeline__group:last-of-type .interaction--webmaster:last-of-type .ii-sub-menu {
//     top: unset;
//     bottom: 18px;
//   }
//   .timeline__group:last-of-type .interaction--webmaster:last-of-type .ii-menu__tooltip {
//     bottom: unset;
//     top: 22px;

//     &::after {
//       bottom: unset;
//       top: -12px;
//       border-top-color: transparent;
//       border-bottom-color: #0092F2;
//     }
//   }


  // .timeline__group:last-of-type .interaction:last-of-type:not(:first-of-type) .ii-sub-menu {
  //   top: unset;
  //   bottom: 18px;
  // }
  .timeline__group:last-of-type .interaction:last-of-type .ii-sub-menu {
    top: unset;
    bottom: 18px;
  }
  .timeline__group:last-of-type .interaction:last-of-type .ii-menu__tooltip {
    bottom: unset;
    top: 22px;

    &::after {
      bottom: unset;
      top: -12px;
      border-top-color: transparent;
      border-bottom-color: #0092F2;
    }
  }