.global-preloader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  // &__wrap {
  //   width: 160px;
  //   position: absolute;
  //   height: 160px;
  //   // background-image: url("https://s3.eu-west-2.amazonaws.com/cdn.communication.diva.mrwbl.com/kostya/sources/connect-user-3.jpeg?5e70b91e04bd3f74147d9e8a791c5f15");
  //   // overflow: hidden;
  //   // border-radius: 50%;
  //   // background-size: contain;
  //   // background-repeat: no-repeat;
  //   // animation: rot 1s infinite;
  // }
}

// @keyframes rot {
//   from {transform: rotateZ(0deg)}
//   to {transform: rotateZ(360deg)}
// }