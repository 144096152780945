.notification {
  &__pending {
    height: 60px;
    width: 350px;
    border-radius: 6px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__item {
    display: flex;
    border-radius: 6px;
    color: #000;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    padding: 13px;
    padding-right: 2px;
    margin-bottom: 5px;
    justify-content: space-between;
    &--viewed {
      opacity: 0.5;
    }
    &--large {
      height: 75px;
    }
    &--new {
      width: 350px;
      min-width: 350px;
      min-height: 60px;
      height: 60px;
      max-height: 80px;
      margin: 0 10px;
      padding: 4px !important;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &--new-red {
      border: 3px solid #f9a9b0;
      background-color: #f9a9b06e !important;
    }
    &--new-yellow {
      border: 3px solid rgb(242, 204, 12);
      background-color: rgba(242, 204, 12, 0.5) !important;
    }
    &--new-purple {
      border: 3px solid rgb(208, 129, 232);
      background-color: rgba(208, 129, 232, 0.5) !important;
    }
    &--new-green {
      border: 3px solid rgb(1, 223, 133);
      background-color: rgba(1, 223, 133, 0.5) !important;
    }
    &--new-orange {
      border: 3px solid rgb(254, 140, 55);
      background-color: rgba(254, 140, 55, 0.5) !important;
    }
    &--new-blue {
      border: 3px solid rgb(0, 146, 242);
      background-color: rgba(0, 146, 242, 0.5) !important;
    }
  }
  &__item,
  &__pending {
    &--red {
      background-color: #f9a9b0;
    }
    &--yellow {
      background-color: rgba(242, 204, 12, 0.7);
    }
    &--purple {
      background-color: rgba(208, 129, 232, 0.9);
    }
    &--green {
      background-color: rgba(1, 223, 133, 0.7);
    }
    &--orange {
      background-color: rgba(254, 140, 55, 0.7);
    }
    &--blue {
      background-color: rgba(0, 146, 242, 0.7);

      .notification__link {
        color: #fff;
        display: inline-block;
      }
    }
    &--child {
      display: block;
      border-radius: 6px;
      padding: 2px;
    }
  }
  &__link {
    color: #0092f2;
    text-decoration: underline;
    font-style: italic;
    cursor: pointer;
    padding: 0;
    margin: 0 4px;
    text-align: start;

    &:disabled {
      color: #000;
      cursor: default;
    }
    &--reminder {
      max-width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &--text {
      text-decoration: none;
    }
    &--change-number {
      max-width: 255px;
      margin: 0 4px 0 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &--message {
      display: block;
      max-width: 265px;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #fff;
      text-decoration: none;
    }
    &--name {
      display: inline-block;
      max-width: 140px;
      margin: 0 4px 0 0;
      vertical-align: bottom;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &--related-message {
      max-width: 156px;
      margin: 0 4px 0 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-decoration: none;
    }
    &--related-name {
      max-width: 215px;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &--profile {
      max-width: 230px;
      margin: 0 4px 0 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &--client {
      max-width: 135px;
      margin: 0 4px 0 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &--session-contact {
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 200px;
    }
    &--long-session {
      max-width: 250px;
      margin: 0 4px 0 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &--mail-caller {
      text-decoration: none;
    }
  }
  &__text {
    color: #000;
    margin: 0 4px;
    padding: 0;
    text-decoration: underline;
    font-style: italic;
    letter-spacing: 0.3px;

    &--mail-caller {
      text-decoration: none;
      color: #fff;
      letter-spacing: 0.5px;
    }
  }
  &__request {
    display: flex;
    flex-direction: column;
  }
  &__request-btn {
    color: #2e2e30;
    font-size: 14px;
    padding: 0;
    margin: 0;
    padding: 2px 7px;
    border-radius: 4px;

    &--cancel {
      background-color: #f8737f;
    }
    &--accept {
      background-color: #01df85;
      margin: 5px 0;
    }
  }
  &__read {
    background-color: #fff;
    font-size: 14px;
    border-radius: 4px;
    letter-spacing: 0.88px;
    padding: 2px 7px;
  }
  &__item-text {
    margin: auto 0;
    padding-right: 5px;
  }
  &__item-date {
    width: 65px;
    min-width: 65px;
    color: #000001;
    font-size: 10px;
    letter-spacing: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__invitation-btns {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    button {
      color: #2e2e30;
      font-size: 12px;
      margin: 0;
      padding: 2px 8px;
      border-radius: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform: translateX(-50%);

      &:first-child {
        margin-right: 10px;
        background-color: #f8737f;
        margin-left: 50%;

        &:hover {
          background-color: #e75d68;
        }
      }
      &:last-child {
        background-color: #01df85;
        &:hover {
          background-color: #03c275;
        }
      }
    }
  }
}
