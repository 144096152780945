.filter-zone {
  padding-right: 11px;
  position: relative;

  &__search-input {
    width: 100%;
    padding: 11px 50px 11px 10px;
    outline: none;
    border: solid 1px #ffffff;
    border-radius: 10px;
    background-color:#fff;
    font-family: Roboto;	
    font-size: 14px;	
    letter-spacing: 0.88px;	
    line-height: 19px;

    &::placeholder {
      font: 14px sans-serif;
      color: #bfbfbf;
      // color: #D7D7D8;
    }
  }

  &__btn {
    position: absolute;
    right: 15px;
    top: 12px;
    cursor: pointer;

    &--search {
      color: #bfbfbf;
    }
    &--clear {
      color: red;
    }
  }
  
  &__toggle-btn {
    position: absolute;
    right: 35px;

  }
  &__toggle-btn-icon-wrap {
    position: absolute;
    right: 5px;
    top: 16px;
    background-color: #bfbfbf;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    color: #fff;
    font-size: 12px;

    &--active {
      top: 8px;
      right: 0px;
      background-color: #0092F2;
    }
  }
}

.filter-select {
  position: relative;
  font: 14px sans-serif;
  color: #bfbfbf;

  &__input-wrap {
    display: flex;
    align-items: center;
    user-select: none;
    background-color: #fff;
    border: solid 1px #fff;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin: 12px 0 10px;
    outline: none;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.88px;	
    line-height: 19px;
    padding: 2px 10px;
  }

      
  &__input-value {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__input {
    background: 0;
    border: 0;
    font-size: inherit;
    outline: 0;
    padding: 0;
    width: 1px;
    color: transparent;
    left: -100px;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  &__indicator {
    pointer-events: none;
    position: absolute;
    right: 10px;
  }
  &__list {
    position: absolute;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    margin: 0;
    padding: 0;
    top: 25px;
    list-style: none;
    z-index: 2;
    background-color: #fff;
    width: 100%;
  

    &--hidden {
      display: none;
    }
  }

  &__option {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding:5px;
    width: 100%;
    
    &--active {
      color: #0092F2;
    }
    
    &--focused {
      background-color:#f6f6f6;
      color: #000;
      cursor: pointer;
    }
  }
}