.conference-participant {
  display: flex;
  align-items: center;
  padding: 3px 8px 3px 3px;
  background-color: #fff;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid transparent;
  position: relative;
  cursor: pointer;
  font-size: 12px;

  &--small {
    font-size: 13px;
    color: #fff;
    background-color: grey;
    padding: 2px 5px;
    border-radius: 25px;
    text-align: center;
    display: unset;
    margin-right: 2px;
  }
  &--small-online {
    background-color: #00a562;
  }
  &--online {
    border-color: #00a562;
  }
  &--busy,
  &--host {
    border-color: #f8737f;
  }
  &--offline {
    border-color: grey;
  }
  &--checkable {
    background-color: #e6ebf1;
  }
  &__img-wrap {
    width: 32px;
    min-width: 32px;
    height: 32px;
    background-color: #f2f5f7;
    border-radius: 50%;

    & img {
      border-radius: 50%;
    }
  }
  &__status {
    width: 8px;
    height: 8px;
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
  }
  &__name {
    color: #808080;
    margin: 0 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__conference-status {
    margin-left: auto;
    color: #808080;
    font-style: italic;
    padding-right: 3px;
  }
  &__creator {
    color: #fff;
    background-color: #f8737f;
    border-radius: 25px;
    padding: 1px 10px;
  }
  &__checkbox {
    position: absolute;
    visibility: hidden;
    right: 6px;
    outline: none;
  }
  &__checkbox::after {
    display: block;
    visibility: visible;
    cursor: pointer;
    content: "";
    background-color: #fff;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    position: absolute;
    left: -2px;
    top: -2px;
    border: solid 1px #bdbdbd;
  }
  &__checkbox:checked::after {
    display: block;
    visibility: visible;
    content: "";
    background-color: #0092f2;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    position: absolute;
    left: -2px;
    top: -2px;
  }
}

.general-conference-full-mode .conference-participant--offline {
  cursor: default;
}
.general-conference-full-mode .conference-participant--offline .conference-participant__checkbox::after {
  display: none;
}
