/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/media/fonts/Roboto Regular/roboto-v19-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('/media/fonts/Roboto Regular/roboto-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/media/fonts/Roboto Regular/roboto-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/media/fonts/Roboto Regular/roboto-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/media/fonts/Roboto Regular/roboto-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/media/fonts/Roboto Regular/roboto-v19-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('/media/fonts/Roboto Medium/roboto-v19-latin-500.eot');
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('/media/fonts/Roboto Medium/roboto-v19-latin-500.eot?#iefix') format('embedded-opentype'),
       url('/media/fonts/Roboto Medium/roboto-v19-latin-500.woff2') format('woff2'),
       url('/media/fonts/Roboto Medium/roboto-v19-latin-500.woff') format('woff'),
       url('/media/fonts/Roboto Medium/roboto-v19-latin-500.ttf') format('truetype'),
       url('/media/fonts/Roboto Medium/roboto-v19-latin-500.svg#Roboto') format('svg');
}
/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('/media/fonts/Roboto Medium Italic/roboto-v19-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
       url('/media/fonts/Roboto Medium Italic/roboto-v19-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/media/fonts/Roboto Medium Italic/roboto-v19-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/media/fonts/Roboto Medium Italic/roboto-v19-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('/media/fonts/Roboto Medium Italic/roboto-v19-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/media/fonts/Roboto Medium Italic/roboto-v19-latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('/media/fonts/Roboto Bold/roboto-v19-latin-700.eot');
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('/media/fonts/Roboto Bold/roboto-v19-latin-700.eot?#iefix') format('embedded-opentype'),
       url('/media/fonts/Roboto Bold/roboto-v19-latin-700.woff2') format('woff2'),
       url('/media/fonts/Roboto Bold/roboto-v19-latin-700.woff') format('woff'),
       url('/media/fonts/Roboto Bold/roboto-v19-latin-700.ttf') format('truetype'),
       url('/media/fonts/Roboto Bold/roboto-v19-latin-700.svg#Roboto') format('svg');
}