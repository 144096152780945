body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.suspense {
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 200;
}

.form-error-text__wrap{
  position: absolute;
  bottom: calc(0px - 3px);
  z-index: 1;
  width: 100%;
}
.form-error-text{
  color: #dc3545;
  font-size: 12px;
  position: absolute;
  left: 0px;
}

.z-index-popup {
  z-index: 500;
}

.react-select {
  $block: &;

  #{$block}__control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 0;
    user-select: none;
    outline: none;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.88px;
    line-height: 19px;
    padding: 6px;
    border-radius: 6px;
    color: #404040;
    background-color: #f6f9fc;
    border: none;
    box-shadow: none;
    transition: none;

    &--menu-is-open {
      border: none;
      border-radius: 6px 6px 0 0;

      #{$block}__indicator svg {
        fill: rgb(0, 146, 242);
      }
    }
  }

  #{$block}__indicators {

  }

  #{$block}__indicator {
    margin: 0;
    padding: 0;
    color: #bdbdbd;

    svg {
      height: 16px;
      width: 16px;
    }

    &:hover {
      color: #bdbdbd;
    }
  }

  #{$block}__indicator-separator {
    display: none;
  }

  #{$block}__value-container {
    padding: 0;
    height: 20px;
  }

  #{$block}__menu {
    position: absolute;
    text-align: left;
    margin: 0;
    padding: 6px;
    list-style: none;
    background-color: #f6f9fc;
    color: #404040;
    width: 100%;
    max-height: 400px;
    overflow: auto;
    box-shadow: none;
    border-radius: 0 0 6px 6px;
    font-size: 14px;
    z-index: 3;

    &--hidden {
      display: none;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    &-list {
      padding: 0;
    }
  }

  #{$block}__option {
    padding: 5px 0;
    cursor: pointer;

    &:hover {
      background-color: #f6f6f6 !important;
    }

    &--is-focused {
      color: inherit;
      background-color: inherit;
    }

    &--is-selected {
      background-color: inherit;
      color: #404040;
    }
  }

  #{$block}__input {
    background: 0;
    border: 0;
    font-size: inherit;
    outline: 0;
    padding: 0;
    width: 1px;
    color: transparent;
    left: -100px;
    opacity: 0;
    position: relative;
    transform: scale(0);
  }
}

/* Updated error classes */
.input-error-text {
  position: absolute;
  z-index: 1;
  bottom: -13px;
  color: #dc3545;
  font-size: 11px;
  line-height: 1;
}

.input-error {
  border: 1px solid red !important;
}

/* Visually Hidden */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

/* Animations */
.animated-init{
  animation: slideInRight 0.5s;
}
.animated-init-left{
  animation: slideInLeft 0.5s;
}
.animated-init-bottom{
  animation: slideInBottom 0.5s;
}

@keyframes slideInRight{
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft{
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInBottom{
  from {
    opacity: 0;
    transform: translate3d(0, 40%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/* #popups-root {
  position: absolute;
  left: 0;
  top: 0;
} */
