.chat-creator-form {
  height: 34px;
  width: 100%;
  border-radius: 6px;
  border: solid 1px #0092F2;
  display: flex;
  align-items: center;
  font-size: 14px;

  &__wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__input {
    flex-grow: 1;
    outline: none;
    border: none;
    height: 20px;
    line-height: 1;
    padding: 0px 16px;
    width: 100%;
    
    &::placeholder {
      color: #bdbdbd;
    }
  }

  &__btn {
    &--keyboard {
      cursor: pointer;
      margin-left: 5px;
    }

    &--submit {
      padding: 0;
      margin-left: auto;
      cursor: pointer;
      margin-right: 5px;
      height: 20px;
    }
  }
}