.sales-btn {
  display: inline-flex;
  padding: 0;

  &--new-messages{
    &::after{
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 100%;
      background-color: red;
      top: 16px;
      right: 55px;
    }
  }
}