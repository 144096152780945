.dialpad__btn--open {
  display: flex;
  
  & > svg {
    margin-right: 3px;
  }
}

.dialpad-box {
  position: absolute;
  width: 290px;
  background-color: #fff;
  padding: 20px;
  top: 36px;
  left: 32px;
  z-index: 10;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.2);
  height: 416px;

  &__btns{
    text-align: center;
  }
  &__btn {
    color: #fff;
    background-color: #000;
    width: 64px;
    height: 64px;
    margin: 1px;
    font-size: 14px;
    border-radius: 5px;

    &--remove-all {
      position: absolute;
      cursor: pointer;
      padding: 15px;
      top: 26px;
      right: 26px;
      height: 15px;
      width: 15px;
      border: none;
      outline: none;
      background-color: transparent;
      z-index: 1;

      &::before, &::after {
        content: '';
        position: absolute;
        top: 8px;
        height: 15px;
        width: 2px;
        background-color: red;
      }
       &::before {
        transform: rotate(45deg)
      }
      
      &::after {
        transform: rotate(-45deg)
      }
    }

    &--sharp, &--star, &--plus {
      position: absolute;
      width: 40px;
      height: 40px;
      background-color: #808080;
    }

    &--sharp {
      left: 58px;
      top: 289px;
    }
    &--star {
      left: 190px;
      top: 289px;
    }
    &--plus {
      left: 124px;
      top: 354px;
    }

    &--call, &--remove {
      position: absolute;
      top: 342px;
      left: 48px;
      background-color: #f6f6f8;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--call > svg {
      margin: 0;
    }
    &--remove {
      left: 178px;
     
      &::before, &::after {
        content: '';
        position: absolute;
        display: inline-block;
        height: 25px;
        width: 5px;
        right: 28px;
        background-color: #F8737F;
      }

      &::before {
        bottom: 12px;
        transform: rotate(135deg)
      }
      
      &::after {
        top: 12px;
        transform: rotate(45deg)
      }
    }
    
  }
}

.dialpad-select {

  margin-bottom: 20px;
    &__control {

        background: #fff;
        padding: 5px;

        &:not(.fective) {
            box-shadow: none;
            border: none;
        }

        &--is-focused {

            &:hover,
            &:not(:hover) {
                border-color: transparent;
            }
        }
    }

    &__input,
    &__placeholder {
        &:not(.fective) {
            font-size: 14px;
            line-height: 18px;
        }
    }

    &__menu {
        padding-right: 7px;
        margin: 0;
        padding: 0;
        width: 230px !important;

        &:not(.fective) {
            background-color: #fff;
            border-radius: yellow;
            border: 1px solid black;
            box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.1);
            left: 106%;
            margin: 0;
            position: absolute;
            top: 0;
        }
        &-list:not(.fective) {
            max-height: 400px;
            padding: 5px 7px 5px 5px;
            font-size: 14px;
            line-height: 20px;
        }
    }

    &__value-container {
        flex: 1 0;
    }

    &__indicators {
      margin-right: 12px;
    }

    &__indicators-container {
        flex-basis: 60px;
    }
}