.calls-info-section {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  background-color: #f2f5f8;
  padding: 0 10px;
  transform: translate(0); // it needs for  fixed drowdown menu

  & > :nth-child(4) {
    z-index: -1;
  }

  &__blink {
    position: absolute;
    top: 10px;
    left: -20px;
    padding: 15px;
  }

  &__clear-all {
    background-color: #e6ebf1;
    font-size: 12px;
    border-radius: 6px;
    margin-right: 20px;
    white-space: nowrap;
    font-weight: 100;
    padding: 5px 15px;

    &:hover {
      color: #fff;
      background-color: #0092f2;
    }
  }

  &__header {
    display: flex;
    margin: 10px 0;
    justify-content: space-between;
    height: 20px;
    // padding-right: 5px;
    // width: 100%;
    // z-index: 2;

    // .title {
    //   white-space: nowrap;
    //   text-overflow: ellipsis;
    //   overflow: hidden;
    // }

    // .calls-info-section__clear-all {
    //   margin-left: auto;
    // }
  }

  // &__header + div {
  //   .calls-info-section__list {
  //     margin-top: 0;
  //   }
  // }

  &__btn {
    border: 1px solid #0092f2;
    outline: none;
    border-radius: 5px;
    align-self: center;
    padding: 2px 0;
    font-size: 11px;
    width: 65px;
    background: none;
    margin-left: 10px;
    cursor: pointer;

    &--hide {
      position: absolute;
      right: 0;
      top: 0;
      // min-width: 80px;
      border: none;
      outline: none;
      font-size: 10px;
      // height: 20px;
      width: 54px;
      border-bottom-left-radius: 2px;
      background-color: #404040;
      color: #fff;
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        color: #fff;
        background-color: #bdbdbd;
      }

      & > svg {
        margin-left: 5px;
        fill: #fff;
      }
    }
  }

  &__item {
    flex-basis: 235px;
    flex-grow: 1;
    position: relative;
    transform: translate(0);
    width: 20%;
  }
  &__img {
    position: absolute;
    top: 14px;
    left: 0;
    border-left: 4px solid #404040;
    border-right: 4px solid #404040;
    width: 10px;
    height: 12px;
  }
  &__title {
    margin-left: 18px;
    font-size: 14px;
    font-weight: normal;
  }
  &__list {
    margin: 0;
    padding: 0 10px 4px 0;
    list-style: none;
  }
}

.operator {
  background-color: #fff;
  border-radius: 5px;
  width: 170px;
  margin-bottom: 10px;
  border: 1px solid #01df85;
  padding: 3px 0 3px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &--busy {
    border: 1px solid #f8737f;
  }
  &--assistance {
    border: 1px solid #f2cc0c;
  }
  &--offline {
    border: 1px solid rgb(182, 181, 181);
  }

  &__status {
    font-size: 13px;
    margin: 0;
    color: #808080;
  }
  &__unread {
    position: absolute;
    right: 50px;
    top: 8px;
    display: inline-block;
    width: 6px;
    height: 6px;
    background: #ec2828;
    border-radius: 50%;
  }
  &__btns {
    display: inline-flex;
  }
  &__btn {
    display: inline-flex;
    border: none;
    outline: none;
    cursor: pointer;
    vertical-align: bottom;
    padding: 0 5px;
    margin: 0;
    background: none;

    &:active {
      transform: translateY(2px);
    }
    &:disabled {
      cursor: not-allowed;
      transform: unset;
    }
  }
}

.thumb-vertical {
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
  border-radius: inherit;
  border: 1px solid #bfbfbf;
  background-color: #e6ebf1;
  height: 10px;
  transform: translateY(0px);
}
.track-vertical {
  position: absolute;
  width: 6px;
  right: 0;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
  background: #bfbfbf;
}
