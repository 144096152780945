.bookings-list-modal{
  background-color: #fff;
  padding: 10px;
  margin: 0;
  list-style: none;
  box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.534);
  border-radius: 6px;
  font-family: Roboto;


  &__btn {
    margin-top: 10px;
    border: 1px solid #0092f2;
    color: #000;
    border-radius: 3px;
    padding: 5px 10px;
  }

  &__load-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 804px;
  }
  &__list{
    padding: 0;
    margin: 0;
  }
  &__list-container{
    height: 500px;
    width: 804px;
  }
  &__no-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 14px;
    color: #bfbfbf;
  }
}