.contact-details {
  display: block;
  position: absolute;
  width: 100%;
  max-width: 350px;
  height: auto;
  max-height: calc( 100% - 25px );
  right: 0;
  top: 25px;
  text-align: left;
  padding: 10px 0;
  background-color: #fff;
  border-bottom: 2px solid #0092F2;
  overflow-y: auto;
  box-shadow: 0px 3px 25px -7px rgba(0,0,0,0.47);
  font-size: 12px;
  font-family: "Roboto";
  z-index: 11;

  &__chat-wrapper {
    position: relative;
  }

  &__header {

    &--header {
      margin-bottom: 5px;
      overflow: hidden;
    }

    &--footer {
      position: relative;
      text-align: center;
      font-size: 10px;
      margin-bottom: 10px;
      letter-spacing: 0.6px;
      line-height: 13px;
      color: #4A4A4A;

      span {
        display: block;
      }
    }

    &--ava {
      position: relative;
      width: 60px;
      height: 60px;
      margin: 0 auto;
  
      &::before {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid #e6edf1;
        top: 0;
      }
  
      &-available {
        &::before {
          content: "";
          background-color: #01df85;
        }
      }

      &-off {
        &::before{
          content: "";
          background-color: #f8737f;
        }
      }
  
      &-img {
        border-radius: 50%;
      }
    }

    &--actions {
      position: absolute;
      display: flex;
      top: 16px;
      left: 60px;

      &-btn {
        margin-left: 10px;
        padding-top: 4px;
      }

      @media  (max-width: 1800px) {
        top: 0px;
        height: 55px;
        flex-wrap: wrap;
        width: 100%;
        &-btn {
          margin-left: 0px;
        }
      }
    }

    .btn-sessions {
      position: relative;

      &__indicator {
        position: absolute;
        top: 0;
        right: 2px;
        font-size: 11px;
      }
    }

    .btn-logs {
      vertical-align: 2px;
    }
  }

  &__body {
    &--item {
      padding: 5px 0;

      &__phone,
      &__mail,
      &__adress,
      &__notes,
      &__map,
      &__link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 19px;
        padding: 2px 35px;
        color: #000001;
        font-size: 12px;
        cursor: pointer;

        .item-icon {
          margin-right: 10px;
        }

        .item__copy {
          display: none;
        }

        &:hover {
          background: #f6f6f8;

          .item__copy {
            display: block;
          }

          .item-icon {
            fill: #0092f2;
            color: #0092f2;
          }
        }
      }

      &__adress {
        position: relative;
        .adress__btn-copy {
          position: absolute;
          right: 35px;
          background: #f6f6f8;
        }
      }

      &__tags {
        display: flex;
        line-height: 15px;
        padding: 5px 35px;
        flex-wrap: wrap;
        cursor: pointer;

        &:hover {
          background: #f6f6f8;
        }

        .contact-details__body--item__tag {
          margin: 0 5px 5px 0;
          padding: 1px 8px;
          border-radius: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &__links {
        display: flex;
        align-items: center;
        padding: 0 35px;
        margin: 6px 0;
      }
    }

    &--divider {
      display: block;
      position: absolute;
      height: 1px;
      width: 50%;
      background-color: #ddd;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    &--trim {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--prewrap {
      white-space: pre-wrap;
      word-break: break-all;
    }
  }

  &__copy{
    &--wrapper{
      position: absolute;
      background-color: #fff;
      padding: 2px 20px;
      left: calc(50% - 28px);
      top: 6px;
    }

    &--btn{
      padding: 0;

      &:disabled{
        cursor: not-allowed;
      }
    }
  }

  &::-webkit-scrollbar{
    width: 4px;
  }
  &::-webkit-scrollbar-track{
    background: transparent;
  }
  &::-webkit-scrollbar-track-piece{
    background: transparent;
  }
  &::-webkit-scrollbar-thumb{
    background: #dbdee4;
    border-radius: 6px;
  }
}