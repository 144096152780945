.conference-item {
  display: flex;
  align-items: center;

  &__main {
    display: flex;
    flex-grow: 1;
    align-items: center;
    background-color: #e2e8f9;
    border-radius: 5px;
    padding: 5px;
    margin: 4px 0;
    min-height: 41px;
    transition: all 1s;
    min-width: 0;
    margin-right: 5px;
    position: relative;
  }
  &.general-conference &__main {
    min-width: 0;
  }
  &__count {
    font-size: 12px;
  }
  &__info {
    flex-grow: 1; // this means that the div will grow in same proportion as the window-size
    flex-shrink: 1; // this means that the div will shrink in same proportion as the window-size
    flex-basis: 0; // this means that the div does not have a starting value as such and will take up screen as per the screen size available for.e.g:- if 3 divs are in the wrapper then each div will take 33%.
    min-width: 0;
    text-align: left;
    align-items: center;
  }
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 5px;
  }
  &__img-wrap {
    width: 20px;
    height: 20px;
  }
  &__img {
    border-radius: 50%;
  }
  &__participants {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    color: #808080;
    font-size: 14px;
  }
  // &__img-wrap {
  //   margin-right:5px;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   cursor: pointer;
  // }
  // &__img {
  //   border-radius:50%;
  //   width: 20px;
  //   height: 20px;
  // }
  &__meta {
    margin: 0;
    line-height: 1.5;
    font-size: 12px;
    color: #808080;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    // display: flex;
    // align-items: center;
    height: 18px;

    & > b {
      color: #0092f2;
      margin: 0 5px;
    }
    & > svg {
      margin-left: 5px;
    }
  }
  &__duration {
    margin: 0;
    font-size: 12px;
    white-space: nowrap;
    min-width: 43px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
  }
  &__timers {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__timer {
    margin-right: 10px;
    font-size: 12px;

    &--hold {
      color: red;
    }
  }
  &__btns {
    display: flex;
  }
  &__btn {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    display: inline-flex;

    &:disabled {
      cursor: not-allowed;
      transform: unset;
      & svg {
        fill: #a5a5a5;
      }
    }
  }

  &__btns-mute-wrap {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    button {
      min-width: 32px;
    }
  }
}

.conference-item--active {
  .conference-item__main {
    min-width: 290px;
  }
  .conference-item__info {
    display: flex;
  }

  .conference-item__timers {
    margin-left: auto;
    font-size: 16px;
  }
  .conference-item__timer {
    font-size: 16px;
    font-style: italic;
  }
}

.conference-item--invited {
  .conference-item__main {
    position: relative;
  }

  .conference-item__invitation {
    position: absolute;
    left: -1px;
    top: -1px;
    right: -1px;
    bottom: -1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;
    z-index: 2;
    cursor: default;
    border-radius: 6px;
    background-color: rgba(1, 223, 133, 0.4);

    &-title {
      color: #0092f2;
      letter-spacing: 1px;
      font-weight: 600;
      font-size: 12px;
      background-color: #fff;
      border-radius: 6px;
      padding: 2px 5px 0px;

      @media (max-width: 1400px) {
        padding: 1px 3px;
        font-size: 11px;
      }
    }

    &-btns {
      width: 100%;
      display: flex;
      justify-content: space-evenly;

      button {
        color: #2e2e30;
        font-size: 12px;
        margin: 0;
        padding: 2px 8px;
        border-radius: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 1400px) {
          padding: 2px 4px;
          font-size: 10px;
        }

        &:first-child {
          background-color: #f8737f;
          &:hover {
            background-color: #e75d68;
          }
        }
        &:last-child {
          background-color: #01df85;
          &:hover {
            background-color: #03c275;
          }
        }
      }
    }
  }
}

.general-conference-full-mode {
  .conference-item__invitation {
    &-title {
      font-size: 12px;
      padding: 2px 5px 0px;
    }

    &-btns button {
      font-size: 14px;
      padding: 2px 8px;
    }
  }
}
