@import "../../variables.scss";
@import "../../fonts.scss";

.sale {
  &__container {
    height: calc(100vh - 60px);
    transform: translate(0px, 0px);
    margin: 0 auto;
    padding-bottom: 10px;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 8px;
      position: absolute;
      top: 0px;
      background-image: url(/media/separator-horizontal.svg);
      background-repeat: repeat;
      background-size: contain;
    }

    & * {
      font-family: Roboto;
    }
  }
  &__spinner-wrap {
    width: 100%;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__main {
    display: flex;
    flex-wrap: nowrap;
    height: calc(100% - 100px);
    padding-bottom: 10px;
  }
  &__notifications {
    position: relative;
    width: 100%;

    &--divider {
      position: absolute;
      width: 100%;
      top: -8px;
      right: 0px;
      z-index: 1;
      &:after {
        content: "";
        display: block;
        height: 8px;
        width: 100%;
        background-image: url(/media/separator-horizontal.svg);
        background-size: contain;
        background-repeat: repeat;
      }
    }
  }
  &__section {
    height: calc(100% - 80px);
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    &-wrap {
      height: calc(100% - 100px);
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
    }
  }
  &__section-left {
    position: relative;
    padding-right: 48px;
    &::after {
      position: absolute;
      top: 20px;
      right: 23px;
      content: "";
      display: block;
      width: 8px;
      min-width: 8px;
      background-image: url("/media/separator-vertical.svg");
      background-size: contain;
      background-repeat: repeat;
      height: 520px;
    }
  }
  &__section-right {
    flex-grow: 1;

    // &::after {
    //   content: "";
    //   display: block;
    //   height: 8px;
    //   width: 100%;
    //   background-image: url("/media/separator-horizontal.svg");
    //   background-size: contain;
    //   background-repeat: repeat;
    // }
  }
}

.separator-horizontal {
  height: 8px;
  width: 100%;
  background-image: url("/media/separator-horizontal.svg");
  background-size: contain;
  background-repeat: repeat;
}
.separator-vertical {
  width: 8px;
  min-width: 8px;
  background-image: url("/media/separator-vertical.svg");
  background-size: contain;
  background-repeat: repeat;
  margin: 20px 15px auto 30px;
  height: 480px;
}

.sale-sidebar {
  max-width: 251px;
  min-width: 194px;
  height: 100%;
  margin-right: 15px;
  width: 30%;
  transform: translate(0);
  z-index: 1;
  padding-top: 10px;
  padding-bottom: 10px;

  &__tooltip {
    background-color: #dc3545;
    position: absolute;
    top: 10px;
    right: 5px;
    color: white;
    font-size: 12px;
    border-radius: 4px;
    z-index: 100;
  }
  &__title {
    font-size: 14px;
    color: black;
    margin: 15px 0px;
    position: relative;

    &--has-link {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
  &__btn {
    color: #fff;
    background-color: #0092f2;
    border: none;
    outline: none;
    border-radius: 5px;
    align-self: center;
    font-size: 13px;
    width: 100%;
    line-height: 1.3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    user-select: none;
    margin: 0;
    padding: 3px 5px;
    margin: 15px 0px 0px;

    &--open {
      animation: fadeInBtn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    &--close {
      animation: fadeOutBtn 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    &--disabled {
      background-color: $color-lightgrey;
      border: 1px solid $color-grey;
      color: $color-grey;
      cursor: not-allowed;
    }

    &:active {
      transform: translateY(1px);
    }
  }
  &__subtitle {
    font-size: 12px;
    color: black;
    margin: 15px 0px;
    padding-left: 18px;
  }
  &__icon {
    margin-right: 5px;
  }
  &__link {
    font-size: 12px;
    color: $color-grey;
    font-weight: 500;
    cursor: pointer;
    margin: auto;
    margin-right: 4px;
    border: none;
    background: none;
    text-decoration: none;
  }
  &__items-wrap {
    transition: height 0.25s;
  }
  &__items {
    padding: 0;
    margin: 0;
    padding-right: 15px;
  }
  &__accordion-indicator {
    margin-right: 15px;
  }
  &__scrollbar-thumb {
    background-color: $color-grey;
    border-radius: 6px;
    position: relative;
    z-index: 2;
  }
}

.session-operators {
  &__wrap {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 100;
    background-color: #f6f9fc;
    border-radius: 6px;
    padding: 0px 5px 10px;
    cursor: default;

    &--comm-page {
      width: 250px;
      z-index: 100000;
      top: initial;
      left: 20px;
      right: initial;
      bottom: 20px;
    }

    .contact-item {
      height: 48px;
      position: relative;
      padding: 5px !important;
    }
  }
  &__title {
    font-size: 12px;
    padding-right: 20px;
    font-weight: 600;
    margin: 5px 0;
  }
  &__close {
    padding: 9px;
    position: absolute;
    right: 5px;
    top: 8px;
    cursor: pointer;

    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 1px;
      right: 8px;
      height: 15px;
      width: 2px;
      background-color: red;
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &__submit {
    background-color: #0092f2;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    margin-top: 10px;
    padding: 5px 0;
    border-radius: 4px;
    border: 1px solid #0092f2;

    &[disabled] {
      background-color: #bfbfbf;
      border: 1px solid #808080;
      color: #808080;
      cursor: not-allowed;
    }
  }
  &__search-input {
    border: 1px solid #0092f2;
    border-radius: 6px;
    width: 100%;
    font-size: 14px;
    padding: 5px;
  }
  &__operators-list {
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
  }

  &__input-wrap {
    position: relative;
    margin-bottom: 10px;
    svg {
      position: absolute;
      right: 4%;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  &__list-wrap {
  }
  &__list-container {
    height: 300px;
  }
  &__list {
    margin: 0;
    padding: 0;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;

    &--active {
      background-color: #8fb6e3;
    }
    & > * {
      pointer-events: none;
    }

    .plus-icon {
      margin-left: auto;
      margin-right: 0;
    }
  }
  &__name {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    text-align: left;
    padding-left: 10px;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 130px;
      font-family: sans-serif !important;
    }
  }
  &__tooltip {
    font-size: 14px;
    background-color: #dc3545;
    color: #fff;
    border-radius: 4px;
  }
  &__tooltip-wrap {
    position: absolute;
    bottom: 30px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__load-wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__no-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 12px;
    color: #bfbfbf;
  }
}

.my-sessions-history,
.my-sessions-history-fixed {
  &__list-container {
    height: 450px;
    width: 100%;
    position: absolute;
    top: 20px;
    left: 0px;
    z-index: 100;
    background-color: white;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    transform: translate(0);
  }
  &__load-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__list {
    margin: 0;
    padding: 10px 0 0;
    list-style: none;

    .session-history {
      &__actions {
        margin-top: 5px;
        justify-content: flex-end;

        .dropdown-menu {
          min-width: auto;
          max-width: none;
          width: 160px;
        }
        .session-operators__wrap {
          position: fixed;
          width: 250px;
          left: 0px;
          z-index: 100000;
        }
      }
      &__tooltip {
        position: relative;
        white-space: normal;
        text-align: justify;
        font-size: 10px;
        font-weight: 500;
      }
    }
  }
  &__no-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 14px;
    color: #bfbfbf;
  }
}
.my-sessions-history-fixed {
  &__list-container {
    height: 250px;
    width: 250px;
    position: fixed;
    left: 10px;
    top: initial;
  }
}

.session {
  display: flex;
  justify-content: flex-start;
  font-family: Roboto;
  background-color: #f6f6f8;
  border-radius: 6px;
  border: 1px solid #f6f6f8;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
  padding: 5px;
  cursor: pointer;
  position: relative;

  &--active {
    background-color: #cde8fc;
    border: 1px solid #0092f2;
  }
  &--unread {
    //  animation: blink 1s linear infinite;
    position: relative;

    &::after {
      content: "";
      box-sizing: border-box;
      display: inline-block;
      width: 10px;
      height: 10px;
      position: absolute;
      border-radius: 50%;
      top: 2px;
      right: 2px;
      border: 2px solid rgb(230, 235, 241);
      background-color: rgb(245, 48, 74);
      animation: unread-pulse 1s infinite;
      transition: all 0.2s ease-in;
    }
  }

  &:hover:not(.session--active) {
    background-color: #d8efff;
    border: 1px solid #7eccff;

    .burger-menu__toggler svg {
      fill: #79c6f9;
    }
  }
  &__avatar-wrap {
    width: 32px;
    height: 32px;
    min-width: 32px;
    margin-right: 3px;
  }
  &__avatar {
    border-radius: 100%;
  }
  &__info {
    color: #808080;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    max-width: 40%;
    overflow: hidden;

    &--fullwidth {
      max-width: none;
    }
  }
  &__info-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__time {
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 500;
    justify-content: center;
    text-align: right;
    max-width: 40%;

    .call-item__timer {
      color: black;
      font-size: 12px;
      font-style: italic;
      margin: 0;
    }

    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__menu-btn-wrap {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 4px;

    .dropdown-menu.dropdown-menu--fixed-right {
      left: 80%;
    }
  }
  &__create-btn-wrap {
    display: flex;
    align-items: center;
  }
  &__create-btn {
    padding: 5px 0;
  }
  &__request {
    position: absolute;
    left: -1px;
    top: -1px;
    right: -1px;
    bottom: -1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;
    z-index: 2;
    cursor: default;
    border-radius: 6px;

    &--assistance {
      background-color: rgba(1, 223, 133, 0.4);
    }
    &--transfer {
      background-color: rgba(0, 146, 242, 0.4);
    }
  }
  &__request-title {
    color: #0092f2;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 12px;
    background-color: #fff;
    border-radius: 6px;
    padding: 1px 5px;
  }
  &__request-actions {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  &__request-btn {
    color: #2e2e30;
    font-size: 12px;
    padding: 0;
    margin: 0;
    padding: 4px 14px;
    border-radius: 6px;

    &--cancel {
      background-color: #f8737f;
    }
    &--accept {
      background-color: #01df85;
    }
  }
}

@keyframes fadeInBtn {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 40px;
  }
}
@keyframes fadeOutBtn {
  0% {
    max-height: 40px;
  }
  100% {
    max-height: 0;
    padding: 0;
  }
}

@media (max-width: 1640px) {
  .sale__section-left {
    flex-basis: 60%;
  }
}
@media (min-width: 1640px) {
  .sale-sidebar {
    width: 251px;
  }
}
@media (max-width: 1500px) {
  .sale-sidebar {
    width: 25%;
  }
}

@keyframes unread-pulse {
  0% {
    box-shadow: 0 0 0 0px rgb(245, 48, 74);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(4, 241, 83, 0);
  }
}
