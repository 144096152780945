.toggle{
  border-radius: 40px;
  width: 45px;
  max-width: 55px;
  height: 20px;
  position: relative;
  cursor: pointer;

  &--true{
    background-color: #28a745;
  }
  &--false{
    background-color: #dc3545;
  }
  &--disabled{
    opacity: 0.5;
    cursor: default;
  }

  &__wrap{
    position: relative;
    margin: 10px;
  }
  &__title{
    font-size: 10px;
    color: #404040;
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
  }
  &__indicator{
    background-color: #fff;
    border-radius: 100%;
    position: absolute;
    width: 45%;
    height: 90%;
    top: 5%;
    transition: transform 0.3s;

    &--true{
      transform: translateX(122%);
    }
    &--false{
      transform: translateX(3%);
    }
  }
}