@import "../../../../variables.scss";

.voicemails {
  &__icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
  }

  &__cell-icon {
    transform: rotateZ(225deg);
    margin-top: -4px;
  }

  &__unread {
    position: absolute;
    right: 12px;
    top: -8px;
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid $color-blue;
    color: #000;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    width: 18px;
    height: 18px;
    line-height: 17px;
    font-size: 10px;
    font-family: Roboto;
  }

  &__mini-player {
    margin-left: auto;
    display: flex;
    width: 60px;

    & .player {
      background-color: transparent;
      position: relative;

      &__control {
        position: absolute;
        right: 0;
        width: 20px;

        &::after {
          border-left-color: $color-blue;
        }
      }

      &__control--pause {
        &::after {
          background: linear-gradient(
            to right,
            $color-lightpink 0% 40%,
            transparent 40% 60%,
            $color-lightpink 60% 100%
          );
        }
      }

      &__slider {
        display: none;
      }

      &__time {
        &:nth-child(2) {
          display: none;
        }

        &:nth-child(4) {
          position: absolute;
          color: $color-lightpink;
          left: -10px;
          font-size: 14px;
          line-height: 1;
        }
      }
    }
  }

  &__list-container {
    width: 100%;
    height: 100%;
  }

  &__load-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__no-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 14px;
    color: #bfbfbf;
  }

  &__list {
    list-style: none;
    padding: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 38px;
    width: 100%;
    border-radius: 6px;
    margin: 4px 0;
    background-color: #fed4d8;
    color: $color-grey;
    padding: 0 12px;
    font-size: 12px;
  }

  &__duration {
    color: $color-lightpink;
    margin: 0px 10px 0 5px;
    font-size: 14px;
    line-height: 1;
  }

  &__voicemail-icon {
    color: ç;
    cursor: pointer;
  }

  &__item-icon {
    margin-right: 5px;
  }

  &__item-name {
    max-width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    width: 100%;

    & span {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__item-date {
    color: #000;
    font-size: 10px;
    font-weight: semi-bold;
  }

  &__spinner {
    width: 20px;
    height: 20px;
  }

  &__list-wrap {
    position: absolute;
    width: 348px;
    height: 520px;
    z-index: 9546;
    top: 45px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);
    padding: 10px;
    font-size: 13px;
    line-height: 1;

    & img {
      width: 20px;
      height: 20px;
    }

    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &::before {
      left: 6px;
      right: auto;
      top: -14px;
      border: 7px solid transparent;
      border-bottom-color: #fff;
      content: "";
      display: inline-block;
      position: absolute;
    }
  }
}
