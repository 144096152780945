.navbar {
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
  min-height: 60px;

  &__clock {
    font-size: 13px;
    font-family: "Russo One", Tahoma;
    font-weight: bold;
    color: #333;
    position: absolute;
    bottom: 0;
    left: 36px;
    cursor: pointer;
  }

  &__active-call {
    min-width: 250px;
    margin-left: auto;
  }

  &__list {
    display: flex;
    list-style: none;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    color: #777;
    width: 100%;
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 30px;
    height: 100%;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      margin-left: auto;
      padding-right: 0;
    }
    &--splitter::after {
      background-color: #e6ebf1;
      content: "";
      position: absolute;
      margin: 0;
      height: 100%;
      width: 8px;
      right: -3px;
      background-image: url(/media/separator-vertical.svg);
      background-size: contain;
      background-repeat: repeat;
    }
  }

  &__keyboard {
    margin-left: 15px;
  }
  &__last-calls {
    margin-left: 15px;
  }

  &__user {
    margin-left: 15px;
  }
  &__user {
    font-weight: bold;
    font-size: 32px;
  }
  &__count-notifications {
    margin: 0;
    padding: 0;
    position: absolute;
    left: 12px;
    top: 2px;
    font-size: 10px;
  }
  &__img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }
  &__icon {
    width: 24px !important;
    height: 24px;
  }
}

.user-info-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  margin: 0;
  margin-right: 10px;

  &__busy-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: solid 2px #e6ebf1;
    position: absolute;
    left: 1px;
    top: -5px;
  }

  &__busy {
    font-size: 32px;
    color: #777;
    font-weight: bold;
    text-decoration: none;
    border: none;
    outline: none;
    margin-left: 15px;
    cursor: unset;
    background-color: none;
    margin: 0 15px;

    &--busy {
      color: #ff1a1a;
    }
    &:disabled {
      background: #dddddd;
    }
  }

  &__mode {
    position: absolute;
    left: -8px;
    margin-top: 16px;
    font-size: 8px;
    font-weight: 600;
    color: #fff;
    background-color: #a05900;
    padding: 1px 3px;
    white-space: nowrap;
    border-radius: 2px;
  }

  &__img-wrap {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__name {
    margin-left: 15px;
  }
}

.user-notifications {
  height: 544px;
  width: 370px;
  position: absolute;
  bottom: -516px;
  top: 25px;
  left: 0px;
  z-index: 100;
  border-radius: 2px;
  background-color: #fff;

  &__pending {
    height: 78px;
    width: 100%;
    border-radius: 6px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__wrap {
    position: relative;
  }
  &__icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
  }
  &__bell-icon {
    width: 16px !important;
    height: 16px !important;
    margin-right: 5px;
    color: #0092f2;
  }
  &__unread {
    position: absolute;
    left: 8px;
    top: -15px;
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid #0092f2;
    color: #000;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    width: 20px;
    height: 20px;
    font-size: 9px;
    line-height: 9px;
    font-family: Roboto;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
  &__title {
    padding: 10px 10px 0;
    height: 33px;
  }
  &__read-all {
    border-radius: 4px;
    padding: 3px 10px 2px;
    background-color: #00a562;
    color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    &:disabled {
      color: #808080;
      background-color: #bfbfbf;
      cursor: not-allowed;
    }
  }
  &__list-container {
    width: 100%;
    height: calc(100% - 33px);
  }
  &__list {
    padding: 15px 10px;
    margin: 0;
  }
  &__item {
    padding-right: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    .notifications {
      &__link {
        &--session-contact {
          max-width: 200px;
        }
      }
    }
  }
  &__load-wrap {
    width: 30px;
    height: 30px;
    margin: auto;
    margin-top: 60%;
  }
  &__no-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 14px;
    color: #bfbfbf;
  }
}
