.contacts__list {
  &--empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 13px;
    color: #bfbfbf;
  }
}

.contact-item {
  display: flex;
  transition: all 0.2s ease-in;
  width: calc(100% - 14px) !important;
  height: 40px !important;
  // overflow: hidden;
  border: 1px solid #f6f6f6;
  background-color: #f6f6f6;
  border-radius: 6px;
  padding: 3px 5px;
  font-family: Roboto;
  margin-left: 3px;
  margin-top: 2px;
  outline: none;

  &--pending {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cacaca;
  }

  &__rating {
    white-space: nowrap;
    svg {
      color: #f2cc0c;
    }
  }
  &__rates {
    margin-left: 5px;
  }
  &__room-control-wrap {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    .contact-item__count {
      position: sticky;
    }
  }

  &::after {
    box-sizing: border-box;
    display: inline-block;
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    top: 2px;
    left: 5px;
    border: 2px solid rgb(230, 235, 241);
    // animation: available-pulse 1s infinite;
    transition: all 0.2s ease-in;
  }

  &--available {
    &::after {
      content: "";
      background-color: rgb(1, 223, 133);
    }
  }

  &--off {
    &::after {
      content: "";
      background-color: #f8737f;
    }
  }

  &--off-today {
    &::after {
      content: "";
      background-color: #933131;
    }
  }

  &--open.contact-item--available::after {
    left: 15px;
  }

  &--with-agent {
    background-color: rgba(253, 5, 232, 0.15);
  }

  &__content-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__control-wrap {
    display: flex;
    align-items: center;

    .contact-item__count {
      position: sticky;
    }
  }

  &__icon {
    font-size: 17px;
    margin-right: 5px;
  }

  &__related {
    position: absolute;
    right: 10px;
    bottom: 0;
    color: #0092f2;
    font-family: Roboto;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    max-width: 40%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__mode-table {
    position: absolute;
    background: #ffffff;
    width: 70%;
    border-radius: 4px;
    top: -2px;
    right: 0;
    border: 1px solid #808080;
    z-index: 10;

    .mode-table {
      &__header {
        width: 100%;
        height: 14px;
        display: flex;
        border: 1px solid #e8ebf1;
        border-radius: 5px 5px 0 0;
        font-size: 11px;
        align-items: center;
        &--item {
          width: 20%;
          font-weight: normal;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &__item {
        height: 14px;
        display: flex;
        align-items: center;
        border: 1px solid #e8ebf1;
        border-top: none;
        font-size: 11px;
        &:last-child {
          border-radius: 0 0 5px 5px;
        }

        &--block {
          position: relative;
          width: 20%;
          text-align: center;
        }

        .name {
          text-transform: capitalize;
        }
      }
    }
  }

  &__mode {
    position: absolute;
    left: 4px;
    bottom: 0;
    font-size: 8px;
    font-weight: 600;
    color: #fff;
    background-color: #a05900;
    padding: 1px 3px;
    white-space: nowrap;
    border-radius: 2px;
  }
  &--focused {
    background-color: #f6f6f8;
  }

  &:hover {
    cursor: pointer;
  }
  &:hover &__btn--room-call {
    display: block;
  }
  &--open {
    background-color: #fff;
    padding: 4px 35px 4px 15px;
    border-radius: 10px;
    height: 83px !important;
    width: calc(100% - 14px) !important;
  }
  &--open &__description,
  &--open &__btn--edit {
    display: -webkit-box;
    position: absolute;
    left: 16px;
    bottom: 7px;

    animation: showRow 1s forwards;
  }
  &--open &__btn--edit {
    top: 5px;
    left: 2px;
  }
  &--open &__btn--edit,
  &--open &__btns {
    opacity: 1;
    transition: all 0.2s ease-in;
    z-index: 1;
  }
  &--open &__btns {
    right: 5px;
    top: 0;
  }
  &--open &__info:hover > .contact-item__tooltip {
    visibility: visible;
  }

  &--open .contact-item__info {
    width: calc(100% - 28px);
  }
  &--active {
    border: 1px solid #0092f2;
    border-radius: 4px;
    background-color: #fcfcfd;
  }
  &__item {
    padding: 5px;
    cursor: pointer;
    position: relative;

    &--status {
      position: absolute;
      top: 18px;
      left: 2px;
      text-align: center;
      padding: 2px 4px;
      width: 50px;
      // display: block;
      border: 1px solid red;
      background: lightgreen;
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
      border-radius: 3px;
      font-size: 11px;
    }
  }
  &__img-wrap {
    width: 32px;
    height: 32px;
    position: relative;
    margin-right: 5px;
    min-width: 32px;

    &--micro {
      height: 10px;
      width: 10px;
      min-width: 10px;
      margin-right: 4px;
      // border: 1px solid #0092f2;
      // border-radius: 50%;
    }

    &--active {
      .lazy-load__container {
        box-shadow: 0px 0 3px 2px #00bf0d85;
      }
    }

    svg {
      font-size: 14px;
      position: absolute;
      bottom: 0;
      left: -2px;
      background: #fff;
      border: 2px solid #e6ebf1;
      border-radius: 50%;
    }
  }
  &__status {
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    top: -3px;
    left: 0;
    background-color: red;
    z-index: 2;

    &::after {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: calc(100% - 14px);
      top: -6px;
      z-index: 9999;
    }
  }
  &__pinned {
    position: absolute;
    top: -6px;
    left: -2px;
    width: 14px;
    transition: all 0.2s ease-in;
    color: #0092f2;
    transform: rotate(-45deg);
    font-size: 12px;
  }
  &__tooltip {
    visibility: hidden;
    -width: 60px;
    padding: 5px;
    font-size: 12px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    bottom: 24px;
    left: 56px;
    z-index: 1;
    display: flex;
    align-items: center;
  }

  &--marked {
    &::before {
      content: "";
      position: absolute;
      border: 5px solid #ff0000;
      border-right-color: transparent;
      border-top-color: transparent;
      bottom: 0;
      left: 0;
    }
  }
  &__img {
    border-radius: 50%;
  }
  &__exclusive {
    position: absolute;
    right: 10px;
    bottom: -2px;

    &--icon {
      background: transparent !important;
      border-color: transparent !important;
      color: #f2cc0c;
    }
  }
  &__info {
    color: #808080;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    // .contact-item__list-item{
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   width: auto;
    //   font-size: 14px;
    //   height: 48px;
    // }
  }
  &__name {
    margin: 0;
    width: 70px;
    font-weight: normal;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }

  &__ex {
    color: #f27e3f;
    font-weight: bold;
  }

  &__time {
    color: #000;
    font-size: 10px;
    white-space: nowrap;
    margin-right: 5px;
  }

  &__last-msg {
    color: #808080;
    margin: 5px 0 0;
    font-size: 12px;
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    margin: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    outline: none;
    margin-top: 3px;

    width: calc(100% - 80px);
    position: absolute;
    bottom: 4px;

    &--operator {
      font-style: italic;
    }

    .interaction__text {
      line-height: unset;
      margin: 0;
      font-size: unset;
      white-space: nowrap;
      overflow: hidden;
      color: #808080;
      text-overflow: ellipsis;
    }

    &::-moz-selection {
      background: transparent;
    }
    &::selection {
      background: transparent;
    }
  }
  &__description {
    border-top: 1px solid #bfbfbf;
    padding-top: 5px;
    color: #bfbfbf;
    font-size: 13px;
    width: calc(100% - 55px);
    max-height: 34px;
    overflow: hidden;

    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__btns {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    position: absolute;
    z-index: -1;
    right: -30px;
    opacity: 0;
    transition: all 0.2s ease-in;
  }
  &__btn {
    // margin-bottom: 10px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background: transparent;

    &--call:disabled > svg {
      fill: grey;
    }

    &--mess,
    &--call,
    &--edit,
    &--share,
    &--room-call,
    &--share-contact {
      background-color: none;
      border: none;
      padding: 0;
      outline: none;
    }
    &--room-call {
      display: none;
      margin-left: 4px;
    }
    &--edit {
      width: 18px;
      height: 18px;
      z-index: -1;
      opacity: 0;
      position: absolute;
      top: -20px;
      left: 5px;
      transition: all 0.2s ease-in;
    }
    &--copy {
      background-color: #fff;
      margin: 0 5px 0 0;
      padding: 0;
    }
  }
  &__count {
    position: absolute;
    right: 2px;
    top: 2px;
    border-radius: 0.8em;
    background-color: #0092f2;
    color: #fff;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    width: 1.7em;
    line-height: 1.7em;
    font-size: 10px;

    &--important {
      background-color: #f8737f;
    }

    &--related {
      background-color: #fff;
      color: #000;
    }
  }
  &__draft {
    font-size: 14px;
    font-weight: bold;
    margin-right: 3px;
    color: #f8737f;
  }
  &__busy {
    position: absolute;
    top: -3px;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #f8737f;
    z-index: 2;

    &::after {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: calc(100% - 14px);
      top: -6px;
      z-index: 9999;
    }
  }
  &__sessions-indicator {
    position: absolute;
    top: 0px;
    right: 50px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: Roboto;
    font-style: italic;

    span {
      padding-top: 2px;
      padding-left: 2px;
    }
  }
}

@keyframes showRow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes available-pulse {
  0% {
    box-shadow: 0 0 0 0px rgb(4, 241, 83);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(4, 241, 83, 0);
  }
}
