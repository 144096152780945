@import "../../variables.scss";

%checkbox-shared {
  background-color: $color-blue;
  color: $color-white;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: 4px;
  padding: 5px 6px;
  cursor: pointer;
}

.booked-dropdown {
  overflow: hidden;
  position: absolute;
  height: 0;
  top: 0;
  left: 0;
  opacity: 0;
  transition: height 0.2s;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 3px;
  z-index: 1;

  &__checkbox {
    @extend %checkbox-shared;
    position: relative;
    display: flex;
    justify-content: center;
    width: fit-content;

    &--active {
      background-color: $color-white;
      color: $color-black;
    }
  }
}

.booked-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  cursor: pointer;

  &__checkbox {
    @extend %checkbox-shared;
    width: 100%;
    text-align: center;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.12);
    text-transform: uppercase;
    padding: 5px 0;

    &--active {
      color: $color-black;
      background-color: transparent;
      box-shadow: none;
      font-size: 14px;
      font-weight: normal;
      text-transform: capitalize;
    }
  }

  &__checkbox-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    padding: 0;
  }

  &__checkbox-icon {
    color: lawngreen;
    font-size: 23px;
  }

  &__editor-wrap {
    position: absolute;
  }
}