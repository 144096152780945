.attachments {
  text-align: left;
  font-size: 14px;

  &__title {
    // color: #0092F2;
    margin: 3px 0 8px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  &__links,
  &__images,
  &__contact,
  &__msg,
  &__voice-message,
  &__session {
    border-left: 3px solid #d9d9d9;
    padding: 5px;
    background-color: inherit;
    // margin-right: 10px;
  }
  &__msg .attachment-msg__header svg {
    display: block;
  }
  &__row {
    display: flex;
  }
  &__voice-message {
    padding-right: 7px;
  }
  &__images {
    position: relative;
  }
}

.attachment-link {
  &__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  &__name {
  }
  &__url {
    margin: 5px 0;
    color: #0092f2;
  }
  &__desc {
    margin: 0 0 5px;
    line-height: 1.3;
    color: #858181;
    font-weight: 200;
  }
  &__img {
    max-width: 100px;
    height: 100px;
    max-height: 100px;
    overflow: hidden;
  }
  &__btn {
    position: absolute;
    right: 0;
    padding: 2px 4px;
    border-radius: 4px 0px 4px 4px;
    color: #fff;
    background-color: #00A6AD;
  }
}

.attachment-img {
  display: flex;
  position: relative;
  flex-wrap: wrap;

  &--hidden {
    max-height: 180px;
    overflow: hidden;
  }

  &__btn {
    position: absolute;
    right: 7px;
    bottom: 7px;
    width: 25px;
    height: 25px;
    padding: 0;
    border-radius: 50%;
    color: #fff;
    font-weight: bold;
    font-size: 10px;
    line-height: 25px;
    background-color: #00A6AD;
    &--download {
      opacity: 0;
      z-index: 2;
    }
    &--download-all, &--download {
      top: 0;
      right: 0;
      border-radius: 7px 0 7px 7px;
    }
  }

  &__img-wrap {
    position: relative;
    width: calc(100% / 3);
    height: 90px;
    cursor: pointer;
    display: flex;
    text-align: center;
    &:hover .attachment-img__btn--download {
      opacity: 1;
    }
  }
  &__img {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 4px;

    &:hover {
      filter: blur(0.005px);
      transform: scale(0.99);
    }
  }
}

.attachment-contact {
  &__body {
    display: flex;
    align-items: center;
    font-size: 14px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
    border-radius: 6px;
    border: 1px solid grey;
    background-color: #fff;
    padding: 4px;
  }
  &__img-wrap {
    width: 32px;
    height: 32px;
    min-width: 32px;
    cursor: pointer;
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 1.4;
    font-size: 13px;
    margin: 0 10px;
  }
  &__meta {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
    color: #858181;
  }
  &__actions {
    display: flex;
    flex-wrap: wrap;
  }
  &__btn {
    width: 32px;
    height: 32px;
  }
}

.attachment-msg {
  padding-top: 5px;
  
  &__ava {
    width: 26px;
    min-width: 26px;
    height: 26px;
    cursor: pointer;
    margin: 0 5px;
    img {
      border-radius: 50%;
    }
  }
  &__header {
    display: flex;
    position: relative;
    svg {
      position: absolute;
      display: none;
      right: 0;
      top: 0;
    }
  }
  &__name {
    font-size: 12px;
    color: #bfbfbf;
    margin: 0;
    line-height: 0.9;
  }
  &__time {
    font-style: italic;
    font-size: 11px;
  }
  &__content {
    padding: 5px 0 5px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    p {
      font-size: 12px;
      padding-left: 10px;
    }
  }
}
.attachment-voice-msg {
  display: flex;
}

.attachment-session {
  &__deleted-session {
    font-family: Roboto;
    color: #dc3545;
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
  }
  &__spinner-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__body {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
    border-radius: 6px;
    border: 1px solid grey;
    background-color: #fff;
    padding: 5px 10px;
    margin-bottom: 10px;
    height: 52px;
    max-height: 52px;
    cursor: pointer;
  }
  &__meta {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.chat-attachment {
  position: relative;
  margin: 10px 10px 0;
  padding: 5px 10px;
  background: #fff;
  bottom: 0;
  text-align: left;
  box-shadow: 0 1px 20px rgba(27, 31, 35, 0.15);
  font-size: 13px;

  &__btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-left: 20px;
    z-index: 1;
  }
  & .attachment-msg__content .interaction__text,
  & .attachment-reply__content .interaction__text {
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.attachment-booking {
  height: 85px;

  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 4px;

    &--date {
      font-size: 11px;
      line-height: 15px;
    }
    .booking-icon {
      font-size: 17px;
      margin-left: 10px;
    }
    &--fb-available {
      font-size: 11px;
      font-style: italic;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    height: auto;
    padding: 6px 4px;
    background: #ffffff;
    border-radius: 3px;
    cursor: pointer;

    &--avatar {
      margin-right: 5px;

      .lazy-load__container {
        width: 32px;
        height: 32px;

        img {
          border-radius: 50%;
        }
      }
    }
    &--avatar-pending {
    }
    &--info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 12px;
    }
    &--details {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 4px;
    }
    &--girl,
    &--operator {
      font-weight: 500;
      // color: #0092f2;
      margin-left: 4px;
    }
    &--girl {
      max-width: 115px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      svg {
        margin-left: 3px;
        color: #f2cc0c;
        font-size: 10px;
      }
    }
    &--type {
      text-transform: capitalize;
    }
    &--price {
      position: relative;
      &::before {
        content: "\FFE1";
        position: absolute;
        right: -12px;
      }
    }
    &--type,
    &--time,
    &--duration,
    &--price {
      margin: 0 4px;
    }
    &--pending {
      display: flex;
      width: 100%;
      min-height: 43px;
      justify-content: center;
      align-items: center;
    }
  }

  &__fb-action {
    display: flex;

    &--btn {
      width: 100%;
      padding: 5px;
      font-size: 12px;
      border-radius: 3px;
      color: white;
      background: #997cb5;
      line-height: 12px;
      cursor: pointer;
    }
  }

  &--removed {
    height: 85px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    font-style: italic;
    padding: 5px 0;

    span {
      padding: 10px 30px;
      background: #f3cdcd71;
      border-radius: 5px;
    }
  }
}

.attachment-reply {
  padding-top: 5px;

  &__ava {
    width: 26px;
    min-width: 26px;
    height: 26px;
    cursor: pointer;
    margin: 0 5px;

    display: flex;
    align-items: center;
    font-size: 20px;

    img {
      border-radius: 50%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__reply-btn {
    padding: 0;
    color: #808080;
    transition: color .2s cubic-bezier(0.4, 0.0, 0.2, 1);

    &:hover {
      color: #0092f2;
    }

    svg {
      font-size: 16px;
      transform: scale(-1, 1);
    }
  }

  &__name {
    font-size: 12px;
    color: #808080;
    margin: 0;
    line-height: 0.9;
  }

  &__time {
    font-style: italic;
    font-size: 11px;
  }

  &__content {
    position: relative;
    margin-top: 5px;
    padding: 0 0 5px 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 0;
      bottom: 0 ;
      width: 3px;
      background-color:#0092f2;
      z-index: 1;
    }

    p {
      margin-top: 5px;
      font-size: 12px;
    }
  }

  &__attachments {
    margin-top: 5px;
    font-size: 14px;

    svg {
      margin-right: 5px;
    }
  }
}

.attachment-email {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1px solid #0092f2;
  border-radius: 5px;
  line-height: 1;
  background-color: #cce9fd;
  cursor: pointer;
  
  &__mail-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #fff;

    svg {
      font-size: 15px;
      color: #0092F2;
    }
  }

  &__text {
    margin-left: 12px;
    color: #404040;
    font-size: 14px;
    line-height: 1.2;
    font-family: Roboto;
  }
}
