.sessions-accordion {
  font-family: Roboto;

  &__spinner{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
  }

  .sale-sidebar__subtitle{
    padding: 0;
    font-size: 14px;
    color: #808080;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .sale-sidebar__title {
    color: #808080;
    cursor: pointer;
  }
}